import { View, Text, Pressable } from "react-native";
import styles from "../styles";
import { ProfileIcon } from "../common/ProfileIcon";
import {
  type ConversationSearchItem,
  ConversationUserRole,
} from "../client/DialogClientModels";

// TODO: combine with other dialog list items
export const PublicDialogListItem: React.FC<{
  item: ConversationSearchItem;
  handleItemPress: (item: ConversationSearchItem) => void;
}> = ({ item, handleItemPress }): React.JSX.Element => {
  const topicText = `Dialog with ${item.title}`;

  const creator = item.users.find(
    (user) => user.role === ConversationUserRole.CREATOR,
  );
  const responder = item.users.find(
    (user) => user.role === ConversationUserRole.RESPONDER,
  );

  return (
    <Pressable
      onPress={() => {
        handleItemPress(item);
      }}
    >
      <View style={styles.DialogListItem}>
        <ProfileIcon
          name={creator?.name ?? "?"}
          pictureUrl={creator?.pictureUrl}
        />
        <ProfileIcon
          name={responder?.name ?? "?"}
          pictureUrl={responder?.pictureUrl}
        />
        <Text style={styles.topicText}>{topicText}</Text>
      </View>
    </Pressable>
  );
};
