import { Text, View, ScrollView } from "react-native";
import { useAuthContext } from "../../AuthContext";
import styles from "../../styles";
import { ProfileImage } from "./ProfileImage";
import { ProfileName } from "./ProfileName";
import { ProfileContactInformation } from "./ProfileContactInformation";
import { useEffect } from "react";
import { ProfileBirthdate } from "./ProfileBirthday";
import { AsyncButton } from "../../common/Button";
import { ErrorMessage } from "../../common/ErrorMessage";

export const ProfileScreen: React.FC = () => {
  const { signOut, requiresLogin, setRequiresLogin, user, fetchUserStatus } =
    useAuthContext();

  useEffect(() => {
    if (!requiresLogin) {
      setRequiresLogin(true);
    }
  }, []);

  if (fetchUserStatus === "PENDING") {
    return <></>;
  }

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      {user ? (
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            flex: 1,
            padding: 10,
          }}
        >
          <ProfileImage />
          <ProfileName />
          <View style={styles.privateProfileContainer}>
            <Text style={{ fontSize: 18, textAlign: "center" }}>
              This information is not visible to others
            </Text>
            <ProfileContactInformation />
            <ProfileBirthdate />
          </View>
          <View style={{ marginTop: 80 }}>
            <AsyncButton onPress={signOut} isAlert buttonText="Sign Out" />
          </View>
        </View>
      ) : (
        <View style={{ marginTop: 10, maxWidth: 1000, alignSelf: "center" }}>
          <ErrorMessage errorMessage="Could not load profile" />
        </View>
      )}
    </ScrollView>
  );
};
