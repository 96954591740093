import { Text, View } from "react-native";
import { useAuthContext } from "../AuthContext";
import { AsyncButton } from "./Button";
import { SuspendedUserMessage } from "./SuspendedUserMessage";

export const SignInToContinue: React.FC<{ action?: string }> = ({
  action = "view this page",
}) => {
  const { setRequiresLogin, fetchUserStatus } = useAuthContext();

  const onPress = async (): Promise<void> => {
    setRequiresLogin(true);
  };

  return fetchUserStatus === "SUSPENDED" ? (
    <SuspendedUserMessage />
  ) : (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
      }}
    >
      <Text
        style={{ fontSize: 25, textAlign: "center", marginBottom: 10 }}
      >{`You must be logged in to ${action}`}</Text>
      <Text style={{ fontSize: 25, textAlign: "center", marginBottom: 30 }}>
        {"Sign in or sign up to continue"}
      </Text>
      <AsyncButton buttonText="Sign In / Sign Up" onPress={onPress} />
    </View>
  );
};
