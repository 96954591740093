import { useAuthContext } from "../AuthContext";
import { useEffect, useState } from "react";
import { FlatList, Pressable, Text, View } from "react-native";
import { Feather } from "@expo/vector-icons";

const faqs = [
  {
    question: "What is the Dialog app?",
    answer:
      'Dialog is a place for 1-on-1 text conversations ("dialogs") between people with different opinions, experiences, or identities.',
  },
  {
    question: "What is a dialog?",
    answer:
      'A dialog is a text conversation between 2 people. One person starts the dialog by describing who they would like to talk to (e.g. "a Democrat", "a Republican", "a birdwatcher", "someone who has been homeless"). Another person who matches the description can then join.',
  },
  {
    question: "Why are dialogs limited to 2 people?",
    answer:
      "We believe that 2-person dialogs foster good-faith, open-minded communication in a way that public forum and comment platforms do not." +
      "\n\n2-person dialogs, which are private unless both participants chose to share them publicly, encourage sharing and learning and discourage dunking and trolling.",
  },
  {
    question: "Do I need an account to use Dialog?",
    answer:
      "You need an account to participate (start, join, and send messages) in a dialog, vote on public dialogs, and report dialogs for violating Dialog's rules." +
      "\n\nYou do not need an account to read and share public dialogs.",
  },
  {
    question: "What are Open Dialogs?",
    answer:
      'The "Open Dialogs" tab contains all dialogs that are waiting for someone to join.' +
      "\n\nYou can join an open dialog if you match the dialog description.",
  },
  {
    question: "What are My Dialogs?",
    answer:
      'The "My Dialogs" tab contains all dialogs you started or joined, including' +
      "\n  - open dialogs you started and are waiting for someone to join (these have a question (?) icon)" +
      "\n  - private dialogs between you and someone else (these have a profile icon with other person's profile picture or initials)" +
      "\n  - public dialogs between you and someone else (similar to private dialogs, but with a globe icon)",
  },
  {
    question: "What are Public Dialogs?",
    answer:
      'The "Public Dialogs" tab contains all dialogs that both participants chose to share publicly.' +
      "\n\nAnyone on the internet can read and share public dialogs. However, you need a Dialog account to vote on a public dialog or report a public dialog for violating Dialog's rules.",
  },
  {
    question: "How do I have a dialog with someone?",
    answer:
      "You can either join another person's open dialog or start a new open dialog yourself and wait for someone to join.",
  },
  {
    question: "How do I join to an open dialog?",
    answer:
      'Visit the "Open Dialogs" tab and tap the dialog you want to join. You\'ll be asked to confirm that you match the open dialog description. Tap the "Yes" button to join the dialog.',
  },
  {
    question: "How do I start a new dialog?",
    answer:
      'Visit the "Start Dialog" tab. Enter a description of the person you\'d like to talk to (e.g. "a Democrat", "a Republican", "a birdwatcher", "someone who has been homeless") and tap the "Request Dialog" button.' +
      '\n\nThe dialog will be added to other people\'s "Open Dialogs" tab, where they will see it and be able to join. You can find it in your "My Dialogs" tab.' +
      '\n\nIf you have email notifications enabled, you\'ll receive an email when someone joins to your open dialog. Otherwise, check back in "My Dialogs" to see if someone has join.',
  },
  {
    question: "How will I know when someone joined my dialog?",
    answer:
      'If you have email notifications enabled, you\'ll receive an email when someone joins to your open dialog. Otherwise, check back in "My Dialogs" to see if someone has join.',
  },
  {
    question: "How do I send a message in my dialog?",
    answer:
      'Visit the "My Dialogs" tab and tap the dialog you want to send a message in. Type your message in the text box at the bottom of the screen and tap the "Send" button or press "Return".',
  },
  {
    question: "How do I publish my dialog (make it public)?",
    answer:
      "Both people in a dialog must agree to publish before the dialog becomes public." +
      '\n\nVisit the "My Dialogs" tab and tap the dialog you want to make public. Tap the "Publish" button in the upper right.' +
      '\n\nClick "Request to Publish" in the confirmation window. The other person in the dialog will then be asked to approve. If they approve, the dialog will become public.' +
      "\n\nNote that public dialogs are visible to anyone on the internet. Once a dialog is public, you can't send any new messages in that dialog unless you unpublish it.",
  },
  {
    question:
      "The other person in my dialog requested to publish. How do I approve and make the dialog public?",
    answer:
      'Visit the "My Dialogs" tab and tap the dialog you want to make public. Tap the "Publish" button in the upper right.' +
      '\n\nClick "Publish" in the confirmation window to approve and make the dialog public.' +
      "\n\nNote that public dialogs are visible to anyone on the internet. Once a dialog is public, you can't send any new messages in that dialog unless you unpublish it.",
  },
  {
    question:
      "The other person in my dialog requested to publish. How do I deny the request and keep the dialog private?",
    answer:
      "You do not need to do anything to keep the dialog private." +
      "\n\nBoth people in a dialog must agree to make it public. If you do not approve the request to publish, the dialog will remain private.",
  },
  {
    question: "How do I unpublish a public dialog (make it private again)?",
    answer:
      'Visit the "My Dialogs" tab and tap the dialog you want to unpublish. Tap the "Unpublish" button in the upper right.' +
      '\n\nClick "Unpublish" in the confirmation window to make it private again.' +
      "\n\nOnce a dialog is private, you can add messages to it again." +
      "\n\nYou can republish your dialog at any time.",
  },
  {
    question: "I requested to publish my dialog. How do I undo the request?",
    answer:
      'Visit the "My Dialogs" tab and tap the dialog you no longer want to publish. Tap the "Unpublish" button in the upper right.' +
      '\n\nClick "Undo Request to Publish" in the confirmation window to remove your request.' +
      "\n\nYou can re-request to publish your dialog at any time.",
  },
  {
    question: "How do I vote on a public dialog?",
    answer:
      'Visit the "Public Dialogs" tab and tap the dialog you want to vote on. Tap the thumbs up or thumbs down button at the bottom of the dialog.' +
      "\n\nYou must be logged in to vote on public dialogs." +
      "\n\nYou can only vote once on each public dialog, and you can't remove or change your vote.",
  },
  {
    question: "How do I share a public dialog?",
    answer:
      'Visit the "Public Dialogs" tab and tap the dialog you want to share. Tap the "Share" button in the upper right.' +
      "\n\nSelect the platform you would like to share on, which will navigate you away from the Dialog app to the platform you selected." +
      "\n\nAnyone can share a public dialog. You do not need a Dialog account.",
  },
  {
    question: "How do I view and edit my profile?",
    answer:
      'Visit the "More" tab and then tap "Profile".' +
      "\n\nYou can edit your profile picture and name but not other information.",
  },
  {
    question: "How do I view and edit my email notifications settings?",
    answer:
      'Visit the "More" tab and then tap "Settings"' +
      "\n\nYou can select which types of email notifications, if any, you would like to recieve." +
      '\n\nYou can also tap "Unsubscribe" in any email from Dialog to stop receiving email notifications.' +
      "\n\nNote that we will still send you important emails, such as password reset emails, even if you have disabled all email notifications.",
  },
  {
    question: "How do I report a  dialog that violates Dialog's rules?",
    answer:
      'On any open dialog, public dialog, or dialog in your "My Dialogs" tab, tap the "Report" button in the upper right.' +
      '\n\nTap "Report" in the confirmation window.' +
      "\n\nDialog moderators will review the report and take appropriate action. No further action is required from you." +
      "\n\nYou must be logged in to report a dialog.",
  },
  {
    question: "How many dialogs may I start and/or join?",
    answer:
      "New accounts may start or join a total of 2 dialogs in a 24 hour period." +
      "\n\nAccounts that are at least one week old may start or join 10 dialogs every 24 hours.",
  },
];

export const FaqScreen: React.FC = () => {
  const { requiresLogin, setRequiresLogin } = useAuthContext();

  const ExpandableItem: React.FC<{ question: string; answer: string }> = ({
    question,
    answer,
  }) => {
    const [expanded, setExpanded] = useState(false);

    return (
      <View
        style={{
          flexDirection: "column",
          maxWidth: 800,
          paddingVertical: 10,
          borderBottomWidth: 1,
        }}
      >
        <Pressable
          onPress={() => {
            setExpanded(!expanded);
          }}
          style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <Text style={{ fontSize: 18 }}>{question}</Text>
          <Feather name={expanded ? "minus-circle" : "plus-circle"} size={20} />
        </Pressable>
        {expanded && (
          <View
            style={{
              backgroundColor: "#52be80",
              marginLeft: 10,
              marginRight: 20,
              marginVertical: 10,
              padding: 10,
              borderRadius: 10,
            }}
          >
            <Text style={{ fontSize: 16 }}>{answer}</Text>
          </View>
        )}
      </View>
    );
  };

  const renderItem = ({
    item,
  }: {
    item: { question: string; answer: string };
  }): React.JSX.Element => (
    <ExpandableItem question={item.question} answer={item.answer} />
  );

  useEffect(() => {
    if (requiresLogin) {
      setRequiresLogin(false);
    }
  }, []);

  return (
    <FlatList
      data={faqs}
      renderItem={renderItem}
      refreshing={false}
      contentContainerStyle={{ flexGrow: 1 }}
      style={{ marginLeft: 10 }}
    />
  );
};
