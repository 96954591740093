import { Image, Text, View } from "react-native";

export const NotFoundScreen: React.FC = () => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image
        source={require("../../assets/chairs.png")}
        resizeMode={"contain"}
        style={{
          position: "absolute",
          alignContent: "center",
          alignItems: "center",
          width: "90%",
          maxWidth: 500,
          maxHeight: "90%",
          aspectRatio: 1,
          opacity: 0.15,
        }}
      />
      <Text
        style={{
          fontSize: 28,
          marginTop: 20,
          fontWeight: "400",
          textAlign: "center",
        }}
      >
        {"Sorry\nThis page doesn't exist"}
      </Text>
    </View>
  );
};
