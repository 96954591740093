import {
  type NativeStackScreenProps,
  createNativeStackNavigator,
} from "@react-navigation/native-stack";
import { OpenDialogScreen } from "./OpenDialogScreen";
import {
  OpenDialogListScreen,
  type SlideUpMessage,
} from "./OpenDialogListScreen";
import { useAuthContext } from "../AuthContext";
import { useEffect } from "react";

export type OpenDialogStackParamList = {
  OpenDialogListScreen: { slideUp: SlideUpMessage };
  OpenDialogScreen: {
    title: string;
  };
};

const Stack = createNativeStackNavigator<OpenDialogStackParamList>();

export const OpenDialogsStack: React.FC = () => {
  const { setRequiresLogin } = useAuthContext();

  useEffect(() => {
    setRequiresLogin(false);
  }, []);

  return (
    <Stack.Navigator initialRouteName="OpenDialogListScreen">
      <Stack.Screen
        name="OpenDialogListScreen"
        component={OpenDialogListScreen}
        options={{
          headerTitle: "Select an open dialog to respond",
          title: "Open Dialogs",
          headerShown: true,
          headerBackVisible: false,
        }}
      />
      <Stack.Screen
        name="OpenDialogScreen"
        component={OpenDialogScreen}
        options={({
          route,
        }: NativeStackScreenProps<
          OpenDialogStackParamList,
          "OpenDialogScreen"
        >) => ({
          title: `Dialog with ${route.params.title}`,
          headerTitle: route.params.title,
          headerShown: true,
          headerBackVisible: true,
        })}
      />
    </Stack.Navigator>
  );
};
