import { Text, View } from "react-native";
import styles from "../styles";
import { AppHeader } from "../AppHeader";
import { A } from "@expo/html-elements";

const DIALOG_BASE_URL = process.env.DIALOG_BASE_URL ?? "";

export const SuspendedUserMessage: React.FC = () => {
  return (
    <View style={styles.authFlowContainer}>
      <View style={{ borderBottomColor: "black", borderBottomWidth: 1 }}>
        <AppHeader />
      </View>
      <Text style={styles.authFlowHeader}>
        {"Your account has been suspended for violating Dialog "}
        <A
          href={`${DIALOG_BASE_URL}/rules-and-policies`}
          style={{ color: "blue" }}
        >
          Rules and Policies
        </A>
        {
          ".\n\nIf you believe this is an error, please email support@dialogapp.co"
        }
      </Text>
    </View>
  );
};
