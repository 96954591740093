import { useEffect } from "react";
import { useAuthContext } from "../AuthContext";

export const RedirectScreen: React.FC = () => {
  const { fetchUser, setIsAccountCreationComplete } = useAuthContext();

  useEffect(() => {
    setTimeout(() => {
      redirect().catch(console.error);
    }, 1000);
  }, []);

  const redirect = async (): Promise<void> => {
    await fetchUser();
    setIsAccountCreationComplete(true);
  };

  return <></>;
};
