import { View } from "react-native";
import styles from "../styles";
import { Feather } from "@expo/vector-icons";
import { GiftedAvatar } from "react-native-gifted-chat";
import { getThumbnailUrl } from "./DialogUtil";
import { ConversationStatus } from "../client/DialogClientModels";

type Props = {
  name: string;
  pictureUrl?: string;
  conversationStatus?: ConversationStatus;
};

export const ProfileIcon: React.FC<Props> = ({
  name,
  pictureUrl,
  conversationStatus,
}): React.ReactNode => {
  return (
    <View>
      <GiftedAvatar
        user={{
          _id: name,
          name,
          avatar: pictureUrl && getThumbnailUrl(pictureUrl),
        }}
      />
      {conversationStatus === ConversationStatus.PUBLIC && (
        <View style={styles.globeIcon}>
          <Feather name="globe" size={13} color="white" />
        </View>
      )}
      {conversationStatus === ConversationStatus.REMOVED && (
        <View
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            backgroundColor: "white",
            width: 16,
            height: 16,
            borderRadius: 8,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Feather name="x-circle" size={18} color="red" />
        </View>
      )}
    </View>
  );
};
