import { Text, View } from "react-native";
import { SlideUpMenuButton } from "../../common/SlideUpMenuButton";
import { Feather } from "@expo/vector-icons";
import styles from "../../styles";
import {
  type ConversationMetadataResponse,
  ConversationStatus,
  type ConversationUserResponse,
} from "../../client/DialogClientModels";
import { useAuthContext } from "../../AuthContext";
import { useState } from "react";
import { AsyncButton } from "../../common/Button";
import { useDialogApiContext } from "../../DialogApiContext";

export const MyDialogPublishMenu: React.FC<{
  metadata: ConversationMetadataResponse;
  users: ConversationUserResponse[];
  fetchConversation: () => Promise<void>;
}> = ({ metadata, users, fetchConversation }) => {
  const { dialogClient } = useDialogApiContext();
  const userId = useAuthContext().user?.userId;

  const [isOpen, setIsOpen] = useState(false);

  const user = users.find((user) => user.userId === userId);
  const otherUser = users.find((user) => user.userId !== userId);

  const generateText = (): { messageText: string; buttonText: string } => {
    let messageText: string;
    let buttonText: string;

    if (metadata.status === ConversationStatus.PUBLIC) {
      buttonText = "Unpublish";
      messageText =
        "Unpublishing this dialog will make it private again, and you will be able to post new messages.\n\n" +
        "You can republish later if you want.";
    } else if (user?.requestedPublicConversation) {
      buttonText = "Undo Request to Publish";
      messageText = `You have requested to publish this dialog. "${buttonText}" will undo your request.`;
    } else if (otherUser?.requestedPublicConversation) {
      buttonText = "Publish";
      messageText =
        `${otherUser?.name} would like to publish this dialog. "${buttonText}" will approve and publish.\n\n` +
        "Publishing this dialog will allow anyone to read it, " +
        "and you will no longer be able to post new messages.";
    } else {
      buttonText = "Request to Publish";
      messageText =
        `"${buttonText}" will send ${otherUser?.name} a request to publish. ` +
        "Both of you need to agree before it becomes public.\n\n" +
        "Publishing this dialog will allow anyone to read it, " +
        "and you will no longer be able to post new messages.";
    }

    return {
      messageText,
      buttonText,
    };
  };

  const {
    menuText,
    menuIcon,
  }: { menuText: string; menuIcon: "globe" | "lock" } =
    user?.requestedPublicConversation
      ? { menuText: "Unpublish", menuIcon: "lock" }
      : { menuText: "Publish", menuIcon: "globe" };

  const { messageText, buttonText } = generateText();

  const PublishButton: React.FC = () => {
    return (
      <View style={styles.topMenuButton}>
        <Feather name={menuIcon} size={20} style={styles.topMenuIcon} />
        <Text style={styles.topMenuText}>{menuText}</Text>
      </View>
    );
  };

  const SlideUp: React.FC = () => {
    const onPress = async (): Promise<void> => {
      const requestedPublicConversation = users.find(
        (user) => user.userId === userId,
      )?.requestedPublicConversation;

      if (requestedPublicConversation === undefined) {
        console.error("User not found in conversation");
        return;
      }

      try {
        await dialogClient.updateConversationUser(
          metadata.conversationTopicId,
          metadata.conversationId,
          { requestedPublicConversation: !requestedPublicConversation },
        );
        await fetchConversation();
        setIsOpen(false);
      } catch (error) {
        console.log("Error");
      }
    };

    return (
      <View style={styles.slideUpContentContainer}>
        <Text style={styles.slideUpText}>{messageText}</Text>
        <AsyncButton onPress={onPress} buttonText={buttonText} />
      </View>
    );
  };

  return (
    <SlideUpMenuButton
      triggerComponent={<PublishButton />}
      contentComponent={<SlideUp />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
