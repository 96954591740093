import type React from "react";
import { Pressable, TextInput, View } from "react-native";
import { Feather } from "@expo/vector-icons";
import styles from "../styles";

type Props = {
  query: string;
  placeholder?: string;
  handleSearch: (text: string) => void;
  handleChangeSearchText: (text: string) => void;
};

export const SearchBar: React.FC<Props> = ({
  query: searchQuery,
  placeholder,
  handleSearch,
  handleChangeSearchText,
}) => {
  const handleSubmit = (): void => {
    handleSearch(searchQuery);
  };

  return (
    <View style={styles.searchBarContainer}>
      <TextInput
        style={styles.searchBarInput}
        placeholder={placeholder ?? "Search..."}
        placeholderTextColor="gray"
        onChangeText={handleChangeSearchText}
        onSubmitEditing={handleSubmit}
        value={searchQuery}
        maxLength={100}
      />
      {searchQuery !== "" && (
        <Pressable
          onPress={() => {
            handleChangeSearchText("");
          }}
          style={styles.searchBarClearButton}
        >
          <Feather name="x" size={12} color="white" />
        </Pressable>
      )}

      <Pressable onPress={handleSubmit} style={styles.searchButton}>
        <Feather name="search" size={20} color="white" />
      </Pressable>
    </View>
  );
};
