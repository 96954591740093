import { useState } from "react";
import { Pressable, Text, View } from "react-native";

type Props = {
  editText: string;
  isEditing: boolean;
  onEdit: () => void;
  onSave: () => Promise<void>;
  onCancel: () => void;
};

export const ProfileEditButtons: React.FC<Props> = ({
  editText,
  isEditing,
  onEdit,
  onSave,
  onCancel,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const onSavePress = async (): Promise<void> => {
    setIsSaving(true);
    await onSave();
    setIsSaving(false);
  };

  const onSavePressSync = (): void => {
    onSavePress().catch(console.error);
  };

  if (isEditing) {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          width: 200,
        }}
      >
        <Pressable
          disabled={isSaving}
          onPress={onSavePressSync}
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            width: 70,
          }}
        >
          <Text
            style={{
              color: isSaving ? "gray" : "blue",
              fontSize: 16,
              marginRight: 10,
            }}
          >
            Save
          </Text>
        </Pressable>
        <Pressable
          onPress={onCancel}
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            width: 70,
          }}
        >
          <Text style={{ color: "red", fontSize: 16, marginLeft: 10 }}>
            Cancel
          </Text>
        </Pressable>
      </View>
    );
  }

  return (
    <Pressable onPress={onEdit}>
      <Text style={{ color: "blue", fontSize: 16 }}>{editText}</Text>
    </Pressable>
  );
};
