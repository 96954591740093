import { confirmSignUp, autoSignIn, resendSignUpCode } from "aws-amplify/auth";
import { useState } from "react";
import { Text, TextInput, View } from "react-native";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { type CreateAccountParamList } from "./CreateAccountStack";

import { useAuthContext } from "../AuthContext";
import { AsyncButton } from "../common/Button";
import styles from "../styles";
import { AppHeader } from "../AppHeader";
import { ErrorMessage } from "../common/ErrorMessage";

type Props = NativeStackScreenProps<
  CreateAccountParamList,
  "ConfirmAccountScreen"
>;

export const ConfirmAccountScreen: React.FC<Props> = ({
  route,
  navigation,
}) => {
  const [confirmationCode, setConfirmationCode] = useState<string | undefined>(
    undefined,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [hasResentCode, setHasResentCode] = useState<boolean>(false);

  const { setIsAccountCreationComplete } = useAuthContext();

  const { username, destination, deliveryMedium } = route.params;

  const confirm = async (): Promise<void> => {
    if (confirmationCode) {
      try {
        setErrorMessage(undefined);
        await confirmSignUp({ username, confirmationCode });
        setIsAccountCreationComplete(false);
        await autoSignIn();
        navigation.navigate("AddProfilePictureScreen");
      } catch (error) {
        setErrorMessage("Invalid confirmation code");
      }
    }
  };

  const resendCode = async (): Promise<void> => {
    try {
      await resendSignUpCode({ username });
      setHasResentCode(true);
      setErrorMessage(undefined);
    } catch (error) {
      setErrorMessage("An error occurred");
    }
  };

  const getTitle = (): string => {
    switch (deliveryMedium) {
      case "EMAIL":
        return "Confirm Email Address";
      case "SMS":
        return "Confirm Phone Number";
      default:
        return "Confirm Account";
    }
  };

  const getMessage = (): string => {
    if (hasResentCode) {
      return `We sent a new confirmation code to ${
        destination ?? "your account"
      }. It may take a minute to arrive`;
    }

    return `To log in, please enter the confirmation code we sent to ${
      destination ?? "your account"
    }. It may take a minute to arrive`;
  };

  return (
    <View style={styles.authFlowContainer}>
      <View style={{ borderBottomColor: "black", borderBottomWidth: 1 }}>
        <AppHeader />
      </View>
      <Text style={styles.authFlowHeader}>{getTitle()}</Text>
      <Text style={{ fontSize: 16, marginTop: 10 }}>{getMessage()}</Text>
      <TextInput
        placeholder="Enter your confirmation code"
        placeholderTextColor="gray"
        value={confirmationCode ?? ""}
        onChangeText={setConfirmationCode}
        maxLength={100}
        style={{
          fontSize: 20,
          borderWidth: 1,
          borderRadius: 5,
          padding: 5,
          borderColor: "gray",
          textAlign: "center",
          marginTop: 40,
        }}
      />
      <Text style={styles.authInputLabel}>Confirmation Code</Text>
      <View style={{ marginTop: 20 }}>
        <ErrorMessage errorMessage={errorMessage} />
      </View>
      <View style={{ marginTop: 20 }}>
        <AsyncButton
          onPress={confirm}
          isDisabled={() => !confirmationCode}
          buttonText="Create Account"
        />
      </View>
      <View style={{ marginTop: 10 }}>
        <AsyncButton onPress={resendCode} isAlert buttonText="Resend Code" />
      </View>
    </View>
  );
};
