import { useAuthenticator } from "@aws-amplify/ui-react-native";
import { useState } from "react";
import { Pressable, Text, View } from "react-native";
import styles from "../styles";
import { PasswordInput, UsernameInput } from "../common/AuthInput";
import { AsyncButton } from "../common/Button";
import { signIn } from "aws-amplify/auth";
import { AppHeader } from "../AppHeader";
import { ErrorMessage } from "../common/ErrorMessage";
import { ContinueWithGoogleButton } from "../common/ContinueWithGoogleButton";
import { A } from "@expo/html-elements";

const DIALOG_BASE_URL = process.env.DIALOG_BASE_URL ?? "";

export const SignInScreen: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const { toForgotPassword, toSignUp } = useAuthenticator();

  const isDisabled = (): boolean => {
    return !username || !password;
  };

  const onSignIn = async (): Promise<void> => {
    try {
      await signIn({ username, password });
    } catch (error) {
      setErrorMessage("Invalid username or password");
    }
  };

  return (
    <View style={styles.authFlowContainer}>
      <View style={{ borderBottomColor: "black", borderBottomWidth: 1 }}>
        <AppHeader />
      </View>
      <Text style={styles.authFlowHeader}>Sign In</Text>
      <View style={{ marginVertical: 20 }}>
        <ContinueWithGoogleButton
          onError={() => {
            setErrorMessage("An error occurred");
          }}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: 320,
          marginVertical: 10,
        }}
      >
        <View style={{ flex: 1, height: 1, backgroundColor: "black" }} />
        <Text style={{ marginHorizontal: 5, fontSize: 16 }}>or</Text>
        <View style={{ flex: 1, height: 1, backgroundColor: "black" }} />
      </View>
      <UsernameInput username={username} setUsername={setUsername} />
      <PasswordInput password={password} setPassword={setPassword} />
      <View style={{ marginTop: 5 }}>
        <ErrorMessage errorMessage={errorMessage} />
      </View>
      <Text style={{ fontSize: 16, marginHorizontal: 10, marginBottom: 5 }}>
        {"This site is protected by reCAPTCHA and the Google "}
        <A href="https://policies.google.com/privacy" style={styles.linkText}>
          Privacy Policy
        </A>
        {" and "}
        <A href="https://policies.google.com/terms" style={styles.linkText}>
          Terms of Service
        </A>
        {" apply."}
      </Text>
      <View style={{ marginTop: 10 }}>
        <AsyncButton
          isDisabled={isDisabled}
          onPress={onSignIn}
          buttonText="Sign In"
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          marginTop: 20,
          width: 350,
        }}
      >
        <Pressable
          onPress={toForgotPassword}
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            width: 150,
          }}
        >
          <Text style={styles.linkText}>Forgot Password?</Text>
        </Pressable>
        <Pressable
          onPress={toSignUp}
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            width: 150,
          }}
        >
          <Text style={styles.linkText}>Create Account</Text>
        </Pressable>
      </View>
      <View style={{ margin: 20 }}>
        {/* TODO: This will need to be modified to work on native apps */}
        <A href={`${DIALOG_BASE_URL}/public-dialogs`} style={styles.linkText}>
          Continue to Public Dialogs without signing in
        </A>
      </View>
    </View>
  );
};
