import { Text, TextInput, View } from "react-native";
import styles from "../styles";

type UsernameInputProps = {
  username: string;
  setUsername: (username: string) => void;
};

type PasswordInputProps = {
  password: string;
  setPassword: (password: string) => void;
};

export const UsernameInput: React.FC<UsernameInputProps> = ({
  username,
  setUsername,
}) => {
  return (
    <View style={{ flexDirection: "column", alignItems: "center" }}>
      <TextInput
        placeholder="Enter email address"
        placeholderTextColor="gray"
        value={username ?? ""}
        onChangeText={setUsername}
        maxLength={100}
        style={styles.authTextInput}
        keyboardType="email-address" // TODO change when we allow phone numbers
      />
      <Text style={styles.authInputLabel}>Email</Text>
    </View>
  );
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  password,
  setPassword,
}) => {
  return (
    <View style={{ flexDirection: "column", alignItems: "center" }}>
      <TextInput
        placeholder="Enter your password"
        placeholderTextColor="gray"
        value={password ?? ""}
        onChangeText={setPassword}
        maxLength={30}
        secureTextEntry
        style={styles.authTextInput}
      />
      <Text style={styles.authInputLabel}>Password</Text>
    </View>
  );
};
