import { type ReactNode } from "react";
import { View } from "react-native";
import {
  Menu,
  MenuTrigger,
  MenuOptions,
  renderers,
} from "react-native-popup-menu";
import styles from "../styles";

export const SlideUpMenu: React.FC<{
  triggerComponent: ReactNode;
  contentComponent: ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}> = ({ triggerComponent, contentComponent, isOpen, setIsOpen }) => {
  return (
    <Menu
      onBackdropPress={() => {
        setIsOpen(false);
      }}
      opened={isOpen}
      renderer={renderers.SlideInMenu}
      rendererProps={{ preferredPlacement: "bottom" }}
    >
      <MenuTrigger
        onPress={() => {
          setIsOpen(true);
        }}
      >
        {triggerComponent}
      </MenuTrigger>
      <MenuOptions customStyles={{ optionsContainer: styles.slideUpContainer }}>
        <View>{contentComponent}</View>
      </MenuOptions>
    </Menu>
  );
};
