import { Text, View } from "react-native";
import { SlideUpMenuButton } from "../../common/SlideUpMenuButton";
import { Feather } from "@expo/vector-icons";
import styles from "../../styles";
import { type ConversationMetadataResponse } from "../../client/DialogClientModels";
import { useState } from "react";
import {
  TwitterShareButton,
  XIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

const DIALOG_BASE_URL = process.env.DIALOG_BASE_URL ?? "";

const BUTTON_SIZE = 43;

export const PublicDialogShareMenu: React.FC<{
  metadata: ConversationMetadataResponse;
}> = ({ metadata }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ShareButton: React.FC = () => {
    return (
      <View style={styles.topMenuButton}>
        <Feather name="share-2" size={20} style={styles.topMenuIcon} />
        <Text style={styles.topMenuText}>Share</Text>
      </View>
    );
  };

  const SlideUp: React.FC = () => {
    const { conversationTopicId, conversationId } = metadata;

    const url = `${DIALOG_BASE_URL}/public-dialogs/topic/${conversationTopicId}/conversation/${conversationId}`;
    const title = `Dialog with ${metadata.title}`;

    // TODO: test on mobile device
    if (navigator.share !== undefined) {
      navigator.share({ url, title }).catch(console.error);
    }

    const onShareWindowClose = (): void => {
      setIsOpen(false);
    };

    return (
      <View style={styles.slideUpContentContainer}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginVertical: 5,
          }}
        >
          <View style={{ marginHorizontal: 12 }}>
            <TwitterShareButton
              url={url}
              title={title}
              onShareWindowClose={onShareWindowClose}
            >
              <XIcon size={BUTTON_SIZE} round />
            </TwitterShareButton>
          </View>

          <View style={{ marginHorizontal: 12 }}>
            <FacebookShareButton
              url={url}
              onShareWindowClose={onShareWindowClose}
            >
              <FacebookIcon size={BUTTON_SIZE} round />
            </FacebookShareButton>
          </View>

          <View style={{ marginHorizontal: 12 }}>
            <LinkedinShareButton
              url={url}
              title={title}
              onShareWindowClose={onShareWindowClose}
            >
              <LinkedinIcon size={BUTTON_SIZE} round />
            </LinkedinShareButton>
          </View>

          <View style={{ marginHorizontal: 12 }}>
            <EmailShareButton url={url} subject={title} body={title}>
              <EmailIcon size={BUTTON_SIZE} round />
            </EmailShareButton>
          </View>
        </View>
      </View>
    );
  };

  return (
    <SlideUpMenuButton
      triggerComponent={<ShareButton />}
      contentComponent={<SlideUp />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
