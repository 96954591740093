import { useEffect, useState } from "react";
import { useAuthContext } from "../../AuthContext";
import { Text, View } from "react-native";

export const ProfileBirthdate: React.FC = () => {
  const { user } = useAuthContext();

  const [birthdate, setBirthdate] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (user) {
      setBirthdate(user.birthdate);
    }
  }, [user]);

  return (
    <View style={{ flexDirection: "column", alignItems: "center" }}>
      {birthdate && (
        <View style={{ flexDirection: "column", alignItems: "center" }}>
          <Text style={{ fontSize: 25, marginTop: 20 }}>
            {birthdate &&
              new Date(birthdate).toLocaleDateString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
                timeZone: "UTC",
              })}
          </Text>
          <Text style={{ fontSize: 16, color: "gray" }}>Birthdate</Text>
        </View>
      )}
    </View>
  );
};
