import { View } from "react-native";
import {
  type ConversationMetadataResponse,
  ConversationStatus,
  type ConversationUserReportResponse,
  type ConversationUserResponse,
} from "../../client/DialogClientModels";
import styles from "../../styles";
import { MyDialogPublishMenu } from "./MyDialogPublishMenu";
import { MyDialogReportMenu } from "./MyDialogReportMenu";
import { MyDialogDeleteMenu } from "./MyDialogDeleteMenu";

type Props = {
  metadata?: ConversationMetadataResponse;
  report?: ConversationUserReportResponse;
  users?: ConversationUserResponse[];
  fetchConversation: () => Promise<void>;
  setIsDeleted: () => void;
};

export const MyDialogTopMenu: React.FC<Props> = ({
  metadata,
  report,
  users,
  fetchConversation,
  setIsDeleted,
}) => {
  if (!metadata || !users) {
    return <></>;
  }

  switch (metadata.status) {
    case ConversationStatus.PRIVATE:
      if (report) {
        return <></>;
      }
      return (
        <View style={styles.topMenuContainer}>
          <MyDialogPublishMenu
            metadata={metadata}
            users={users}
            fetchConversation={fetchConversation}
          />
          <MyDialogReportMenu
            metadata={metadata}
            users={users}
            fetchConversation={fetchConversation}
          />
        </View>
      );
    case ConversationStatus.PUBLIC:
      return (
        <View style={styles.topMenuContainer}>
          <MyDialogPublishMenu
            metadata={metadata}
            users={users}
            fetchConversation={fetchConversation}
          />
        </View>
      );
    case ConversationStatus.OPEN:
      return (
        <View style={styles.topMenuContainer}>
          <MyDialogDeleteMenu metadata={metadata} setIsDeleted={setIsDeleted} />
        </View>
      );
    default:
      return <></>;
  }
};
