import { View, Text, Pressable } from "react-native";
import styles from "../styles";
import { ProfileIcon } from "../common/ProfileIcon";
import { type ConversationSearchItem } from "../client/DialogClientModels";
import { makeMyDialogTopicText } from "../common/DialogUtil";

export const MyDialogListItem: React.FC<{
  item: ConversationSearchItem;
  userId: string;
  handleItemPress: (item: ConversationSearchItem) => void;
}> = ({ item, userId, handleItemPress }) => {
  const topicText = makeMyDialogTopicText(item, userId);

  const otherUser = item.users.find((user) => user.userId !== userId);

  return (
    <Pressable
      onPress={() => {
        handleItemPress(item);
      }}
    >
      <View style={styles.DialogListItem}>
        <ProfileIcon
          name={otherUser?.name ?? "?"}
          pictureUrl={otherUser?.pictureUrl}
          conversationStatus={item.status}
        />
        <Text style={styles.topicText}>{topicText}</Text>
      </View>
    </Pressable>
  );
};
