import type React from "react";
import { useState, useEffect } from "react";
import { View, Text, TextInput } from "react-native";
import { useAuthContext } from "../../AuthContext";
import { ProfileEditButtons } from "./ProfileEditButtons";
import { ErrorMessage } from "../../common/ErrorMessage";
import { useDialogApiContext } from "../../DialogApiContext";

export const ProfileName: React.FC = () => {
  const { user } = useAuthContext();

  const { dialogClient } = useDialogApiContext();

  const [name, setName] = useState("");
  const [newName, setNewName] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    if (!name && user?.name) {
      setName(user?.name);
    }
  }, [user?.name]);

  const saveName = async (): Promise<void> => {
    setErrorMessage(undefined);
    if (newName) {
      try {
        await dialogClient.updateName(newName);
        setName(newName);
        setNewName(undefined);
      } catch (error) {
        setErrorMessage("Error updating name");
      }
    }
  };

  return (
    <View
      style={{ flexDirection: "column", alignItems: "center", marginTop: 20 }}
    >
      {newName ? (
        <TextInput
          value={newName}
          onChangeText={(text) => {
            setNewName(text);
          }}
          maxLength={100}
          style={{
            fontSize: 25,
            borderWidth: 1,
            borderColor: "gray",
            textAlign: "center",
          }}
        />
      ) : (
        <Text style={{ fontSize: 25 }}>{name}</Text>
      )}
      <ProfileEditButtons
        editText="Edit Name"
        isEditing={newName !== undefined}
        onEdit={() => {
          setNewName(name);
        }}
        onSave={saveName}
        onCancel={() => {
          setErrorMessage(undefined);
          setNewName(undefined);
        }}
      />
      {errorMessage && (
        <View style={{ marginTop: 10 }}>
          <ErrorMessage errorMessage={errorMessage} />
        </View>
      )}
    </View>
  );
};
