import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react-native";
import { AuthProvider, useAuthContext } from "./AuthContext";
import { I18n } from "aws-amplify/utils";
import { patchFlatListProps } from "react-native-web-refresh-control";
import { View, Platform } from "react-native";
import { MenuProvider } from "react-native-popup-menu";
import styles from "./styles";
import { CreateAccountStack } from "./create-account/CreateAccountStack";
import { SignInScreen } from "./sign-in/SignInScreen";
import { DialogApiProvider } from "./DialogApiContext";
import { AppHeader } from "./AppHeader";
import { MainNavigation } from "./navigation/MainNavigation";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { AddBirthdayStack } from "./create-account/AddBirthdayStack";

const COGNITO_USER_POOL_ID = process.env.COGNITO_USER_POOL_ID ?? "";
const COGNITO_USER_POOL_CLIENT_ID =
  process.env.COGNITO_USER_POOL_CLIENT_ID ?? "";
const COGNITO_AUTH_DOMAIN = process.env.COGNITO_AUTH_DOMAIN ?? "";

const DIALOG_BASE_URL = process.env.DIALOG_BASE_URL ?? "";

const GOOGLE_ANALYTICS_ID = process.env.GOOGLE_ANALYTICS_ID ?? "G-GWVZ28JJ3Q";

const RECAPTCHA_SITE_KEY =
  process.env.RECAPTCHA_SITE_KEY ?? "6LdA4TYqAAAAABXcQL6kTcUD5M9mSuimsYarF0dV";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: COGNITO_USER_POOL_ID,
      userPoolClientId: COGNITO_USER_POOL_CLIENT_ID,
      loginWith: {
        username: true,
        oauth: {
          domain: COGNITO_AUTH_DOMAIN,
          scopes: [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: [
            DIALOG_BASE_URL,
            `https://${COGNITO_AUTH_DOMAIN}/oauth2/idpresponse`,
          ],
          redirectSignOut: [],
          responseType: "code",
          providers: ["Google"],
        },
      },
    },
  },
});

I18n.setLanguage("en");

I18n.putVocabulariesForLanguage("en", {
  Username: "Email or Phone Number",
  "Enter your username": "Enter your email or phone number",
});

// Enables pull to refresh on mobile web
patchFlatListProps();

const MainApp: React.FC = () => {
  return (
    <MenuProvider>
      <View style={styles.appContainer}>
        <AppHeader />
        <MainNavigation />
      </View>
    </MenuProvider>
  );
};

const AuthenticatedApp: React.FC = () => {
  const { requiresLogin, user, isAccountCreationComplete, fetchUserStatus } =
    useAuthContext();

  useEffect(() => {
    if (Platform.OS === "web") {
      const style = document.createElement("style");
      style.innerHTML = `
          .grecaptcha-badge {
            visibility: hidden;
          }
        `;
      document.head.appendChild(style);

      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`;
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
        document.head.removeChild(style);
      };
    }
  }, [requiresLogin]);

  if (fetchUserStatus === "PENDING") {
    return <></>;
  }

  // This status occurs after a user signs up with Google and is redirected back to the app
  if (fetchUserStatus === "MISSING_BIRTHDATE") {
    return <AddBirthdayStack />;
  }

  const isLoggedIn =
    isAccountCreationComplete && user && fetchUserStatus === "COMPLETE";

  if (isLoggedIn ?? !requiresLogin) {
    return <MainApp />;
  }

  return (
    <Authenticator
      components={{ SignUp: CreateAccountStack, SignIn: SignInScreen }}
      signUpAttributes={["birthdate", "name"]}
    >
      <MainApp />
    </Authenticator>
  );
};

export const DialogApp: React.FC = () => {
  useEffect(() => {
    if (GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID);
    }
  }, []);

  return (
    <Authenticator.Provider>
      <AuthProvider>
        <DialogApiProvider>
          <AuthenticatedApp />
        </DialogApiProvider>
      </AuthProvider>
    </Authenticator.Provider>
  );
};
