import { Text, View } from "react-native";
import { useEffect, useState } from "react";
import styles from "../styles";
import { AppHeader } from "../AppHeader";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { type CreateAccountParamList } from "./CreateAccountStack";

type Props = NativeStackScreenProps<
  CreateAccountParamList,
  "CreateAccountCompleteScreen"
>;

export const CreateAccountCompleteScreen: React.FC<Props> = ({
  navigation,
}) => {
  const [secondsRemaining, setSecondsRemaining] = useState<number | undefined>(
    5,
  );

  useEffect(() => {
    if (secondsRemaining === undefined) {
      return;
    }
    if (secondsRemaining === 0) {
      navigation.navigate("RedirectScreen");
    } else {
      setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
      }, 1000);
    }
  }, [secondsRemaining]);

  return (
    <View style={styles.authFlowContainer}>
      <View style={{ borderBottomColor: "black", borderBottomWidth: 1 }}>
        <AppHeader />
      </View>
      <Text style={styles.authFlowHeader}>{"Success!"}</Text>
      <Text
        style={{ fontSize: 16, marginTop: 10 }}
      >{`Redirecting in ${secondsRemaining}`}</Text>
    </View>
  );
};
