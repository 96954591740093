import { type ReactNode } from "react";
import { Pressable, Text, View } from "react-native";
import styles from "../styles";
import { SlideUpMenu } from "./SlideUpMenu";

export const SlideUpMenuButton: React.FC<{
  triggerComponent: ReactNode;
  contentComponent: ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}> = ({ triggerComponent, contentComponent, isOpen, setIsOpen }) => {
  const handleCancelPress = (): void => {
    setIsOpen(false);
  };

  const MenuContent: React.FC = () => {
    return (
      <View style={styles.slideUpContainer}>
        {contentComponent}
        <Pressable onPress={handleCancelPress}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              padding: 10,
            }}
          >
            <Text style={styles.alertLinkText}>Cancel</Text>
          </View>
        </Pressable>
      </View>
    );
  };

  return (
    <SlideUpMenu
      triggerComponent={triggerComponent}
      contentComponent={<MenuContent />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
