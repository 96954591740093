import { useEffect, useState } from "react";
import { Picker } from "@react-native-picker/picker";
import { View, Text } from "react-native";
import styles from "../styles";

const MINIMUM_AGE = 13;

export const BirthdayPicker: React.FC<{
  onChange?: (dateString: string) => void;
}> = ({ onChange }) => {
  const currentYear = new Date().getFullYear();

  const [birthYear, setBirthYear] = useState<number>(currentYear);
  const [birthMonth, setBirthMonth] = useState<string>("January");
  const [birthDayOfMonth, setBirthDayOfMonth] = useState<number>(1);

  useEffect(() => {
    const birthdate = `${birthYear}-${(months.indexOf(birthMonth) + 1)
      .toString()
      .padStart(2, "0")}-${birthDayOfMonth.toString().padStart(2, "0")}`;

    onChange?.(birthdate);
  }, [birthYear, birthMonth, birthDayOfMonth]);

  const years = Array.from({ length: 120 }, (_, i) => currentYear - i);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const days = (): number[] => {
    let nDays = 31;

    while (
      new Date(birthYear, months.indexOf(birthMonth), nDays).getMonth() !==
      months.indexOf(birthMonth)
    ) {
      nDays--;
    }

    return Array.from({ length: nDays }, (_, i) => i + 1);
  };

  return (
    <View
      style={{ flexDirection: "column", marginTop: 20, alignItems: "center" }}
    >
      <View style={{ flexDirection: "row" }}>
        <Picker
          style={{
            fontSize: 19,
            padding: 5,
            marginHorizontal: 2,
            backgroundColor: "lightgray",
            borderRadius: 5,
          }}
          onValueChange={setBirthYear}
        >
          {years.map((year) => (
            <Picker.Item label={year.toString()} value={year} key={year} />
          ))}
        </Picker>
        <Picker
          style={{
            fontSize: 19,
            padding: 5,
            marginHorizontal: 2,
            backgroundColor: "lightgray",
            borderRadius: 5,
          }}
          onValueChange={setBirthMonth}
        >
          {months.map((month) => (
            <Picker.Item label={month} value={month} key={month} />
          ))}
        </Picker>
        <Picker
          style={{
            fontSize: 19,
            padding: 5,
            marginHorizontal: 2,
            backgroundColor: "lightgray",
            borderRadius: 5,
          }}
          onValueChange={setBirthDayOfMonth}
        >
          {days().map((day) => (
            <Picker.Item label={day.toString()} value={day} key={day} />
          ))}
        </Picker>
      </View>
      <Text style={styles.authInputLabel}>Date of Birth</Text>
    </View>
  );
};

export const isBirthdayValid = (birthdate: string): boolean => {
  const currentDate = new Date();

  const minimumAgeDate = new Date(birthdate);

  minimumAgeDate.setFullYear(minimumAgeDate.getFullYear() + MINIMUM_AGE);

  return currentDate >= minimumAgeDate;
};
