import {
  type NativeStackScreenProps,
  createNativeStackNavigator,
} from "@react-navigation/native-stack";
import { MyDialogScreen } from "./MyDialogScreen";
import { MyDialogListScreen } from "./MyDialogListScreen";
import { useAuthContext } from "../AuthContext";
import { useEffect } from "react";

export type MyDialogStackParamList = {
  MyDialogListScreen: undefined;
  MyDialogScreen: {
    conversationTopicId: string;
    conversationId: string;
    title?: string;
    reload?: string;
  };
};

const Stack = createNativeStackNavigator<MyDialogStackParamList>();

export const MyDialogsStack: React.FC = () => {
  const { requiresLogin, setRequiresLogin } = useAuthContext();

  useEffect(() => {
    if (!requiresLogin) {
      setRequiresLogin(false);
    }
  }, []);

  return (
    <Stack.Navigator initialRouteName="MyDialogListScreen">
      <Stack.Screen
        name="MyDialogListScreen"
        component={MyDialogListScreen}
        options={{
          title: "My Dialogs",
          headerShown: true,
          headerBackVisible: false,
        }}
      />
      <Stack.Screen
        name="MyDialogScreen"
        component={MyDialogScreen}
        options={({
          route,
        }: NativeStackScreenProps<
          MyDialogStackParamList,
          "MyDialogScreen"
        >) => ({
          headerTitle: route.params.title,
          title: route.params.title ? `Dialog with ${route.params.title}` : "",
          headerShown: true,
          headerBackVisible: true,
        })}
      />
    </Stack.Navigator>
  );
};
