import type React from "react";
import { type ReactNode } from "react";
import { Pressable, Text, View } from "react-native";
import { Link } from "@react-navigation/native";
import styles from "../styles";

type Props = {
  icon: ReactNode;
  linkText: string;
  linkTo?: string;
  onPress?: () => void;
};

const MenuLinkContents: React.FC<Props> = ({ icon, linkText }) => {
  return (
    <View style={{ flexDirection: "row", alignItems: "center", marginTop: 12 }}>
      {icon}
      <Text style={styles.moreMenuLinkText}>{linkText}</Text>
    </View>
  );
};

export const MenuLink: React.FC<Props> = ({
  icon,
  linkText,
  linkTo,
  onPress,
}) => {
  if (linkTo) {
    return (
      <Link to={linkTo}>
        <MenuLinkContents icon={icon} linkText={linkText} />
      </Link>
    );
  }

  return (
    <Pressable onPress={onPress}>
      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}
      >
        {icon}
        <Text style={styles.moreMenuLinkText}>{linkText}</Text>
      </View>
    </Pressable>
  );
};
