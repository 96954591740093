import { Text, View } from "react-native";

export enum BannerType {
  ALERT = "ALERT",
  INFO = "INFO",
}

type Props = {
  readonly message: string;
  readonly type: BannerType;
};

export const Banner: React.FC<Props> = ({ message, type }) => {
  return (
    <View
      style={{
        backgroundColor: type === BannerType.ALERT ? "red" : "#52be80",
        alignItems: "center",
        padding: 10,
      }}
    >
      <Text style={{ fontSize: 18, textAlign: "center" }}>{message}</Text>
    </View>
  );
};
