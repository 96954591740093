import { useEffect, useState } from "react";
import {
  type ConversationMetadataResponse,
  ConversationStatus,
  type ConversationUserReportResponse,
  type ConversationUserResponse,
} from "../client/DialogClientModels";
import { Banner, BannerType } from "../common/Banner";
import { useDialogApiContext } from "../DialogApiContext";

type Props = {
  metadata: ConversationMetadataResponse;
  report?: ConversationUserReportResponse;
  users: ConversationUserResponse[];
  userId: string;
  isEmpty: boolean;
};

export const MyDialogBanner: React.FC<Props> = ({
  metadata,
  report,
  users,
  userId,
  isEmpty,
}) => {
  const { dialogClient } = useDialogApiContext();

  const [isEmailEnabled, setIsEmailEnabled] = useState<boolean>();

  useEffect(() => {
    if (metadata.status === ConversationStatus.OPEN) {
      fetchEmailNotificationSettings().catch(console.error);
    }
  }, [metadata.status]);

  const fetchEmailNotificationSettings = async (): Promise<void> => {
    try {
      const settings = await dialogClient.getEmailNotificationSettings();
      setIsEmailEnabled(settings.DialogJoined);
    } catch (error) {
      // We do not display an error message
      console.error("Could not load email notification settings");
    }
  };

  if (report) {
    return (
      <Banner
        message="You have reported this dialog. We will review it to determine the appropriate next steps. No further action is required from you."
        type={BannerType.ALERT}
      />
    );
  }

  switch (metadata.status) {
    case ConversationStatus.DELETED:
      return (
        <Banner
          message="This dialog has been deleted"
          type={BannerType.ALERT}
        />
      );
    case ConversationStatus.PUBLIC:
      return (
        <Banner
          message="This dialog is public. You can't post new messages to it unless you unpublish it."
          type={BannerType.INFO}
        />
      );
    case ConversationStatus.OPEN: {
      if (isEmailEnabled === undefined) {
        return;
      }
      const baseMessage = "No one has responded to this dialog yet.";

      const message = isEmailEnabled
        ? `${baseMessage} You will get an email notification when someone responds to this dialog.`
        : `${baseMessage} Visit More > Settings to update your settings if you would like to get an email notification when someone responds. Otherwise check back here for updates.`;

      return <Banner message={message} type={BannerType.INFO} />;
    }
    case ConversationStatus.REMOVED: {
      return (
        <Banner
          message="This dialog has been removed because it violates Dialog's rules. If you believe this is an error, please email support@dialogapp.co"
          type={BannerType.ALERT}
        />
      );
    }
    case ConversationStatus.PRIVATE: {
      const requestedPublicConversation = users.find(
        (user) => user.userId === userId,
      )?.requestedPublicConversation;

      const otherUser = users.find((user) => user.userId !== userId);

      if (requestedPublicConversation) {
        return (
          <Banner
            message={`You have requested to publish this dialog. ${otherUser?.name} has been notified and must approve for it to become public.`}
            type={BannerType.INFO}
          />
        );
      }

      if (otherUser?.requestedPublicConversation) {
        return (
          <Banner
            message={`${otherUser.name} has requested to make this dialog public. Press "Publish" to approve.`}
            type={BannerType.INFO}
          />
        );
      }

      if (isEmpty && otherUser) {
        return (
          <Banner
            message={`Send ${otherUser.name} a message to kick off this dialog!`}
            type={BannerType.INFO}
          />
        );
      }
      return;
    }
    default:
      return undefined;
  }
};
