import { Text, View } from "react-native";
import {
  ProfileImage,
  type ProfileImageStatus,
} from "../menu/profile/ProfileImage";
import { AsyncButton } from "../common/Button";
import { useState } from "react";
import styles from "../styles";
import { AppHeader } from "../AppHeader";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { type CreateAccountParamList } from "./CreateAccountStack";

type Props = NativeStackScreenProps<
  CreateAccountParamList,
  "AddProfilePictureScreen"
>;

export const AddProfilePictureScreen: React.FC<Props> = ({ navigation }) => {
  const [status, setStatus] = useState<ProfileImageStatus>("NO_IMAGE");

  const onFinish = async (): Promise<void> => {
    navigation.navigate("CreateAccountCompleteScreen");
  };

  const getButtonText = (): string => {
    return status === "UPLOADED" ? "Finish" : "Skip";
  };

  const isDisabled = (): boolean => {
    return status === "UPLOADING";
  };

  return (
    <View style={styles.authFlowContainer}>
      <View style={{ borderBottomColor: "black", borderBottomWidth: 1 }}>
        <AppHeader />
      </View>
      <Text style={styles.authFlowHeader}>Add a Profile Picture</Text>
      <Text
        style={{ fontSize: 20, color: "gray", marginTop: 5, marginBottom: 20 }}
      >
        (Optional)
      </Text>
      <ProfileImage onChangeStatus={setStatus} />
      <View style={{ marginTop: 20 }}>
        <AsyncButton
          onPress={onFinish}
          isDisabled={isDisabled}
          buttonText={getButtonText()}
        />
      </View>
    </View>
  );
};
