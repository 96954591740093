import React, { useCallback, useEffect } from "react";
import { ActivityIndicator, Pressable, Text } from "react-native";
import styles from "../styles";

const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY ?? "";
const DIALOG_BASE_URL = process.env.DIALOG_BASE_URL ?? "";

type Props = {
  buttonText: string;
  isDisabled?: () => boolean;
  isAlert?: boolean;
};

type SyncProps = Props & {
  onPress: () => void;
};

type AsyncProps = Props & {
  onPress: () => Promise<void>;
};

type RecaptchaProps = Props & {
  action: string;
  onPress: (token?: string) => Promise<void>;
};

export const Button: React.FC<SyncProps> = ({
  onPress,
  isDisabled = () => false,
  isAlert,
  buttonText,
}) => {
  const asyncOnPress = async (): Promise<void> => {
    onPress();
  };

  return AsyncButton({
    onPress: asyncOnPress,
    isDisabled,
    isAlert,
    buttonText,
  });
};

export const AsyncButton: React.FC<AsyncProps> = ({
  onPress,
  isDisabled = () => false,
  isAlert,
  buttonText,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const style = isDisabled()
    ? styles.disabledButton
    : isAlert
      ? styles.alertButton
      : styles.button;

  const onPressSync = (): void => {
    setIsLoading(true);
    onPress()
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Pressable
      disabled={isDisabled() || isLoading}
      onPress={onPressSync}
      style={style}
    >
      {isLoading ? (
        <ActivityIndicator size="small" />
      ) : (
        <Text style={styles.buttonText}>{buttonText}</Text>
      )}
    </Pressable>
  );
};

// This component only works on web
// Since there is no react-native web implementation of reCAPTCHA,
// we call grecaptcha.enterprise.execute() by creating a script element
// and passing information between the script element and react native code
// via postMessage
export const ReCaptchaButton: React.FC<RecaptchaProps> = ({
  action,
  onPress,
  isDisabled = () => false,
  isAlert,
  buttonText,
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.innerHTML = `
      function handleReCaptchaActionMessage() {
        if (
          event.origin === "${DIALOG_BASE_URL}" &&
          event.data?.type === 'recaptcha-action' &&
          event.data.action === "${action}"
        ) {
          grecaptcha.enterprise.execute("${RECAPTCHA_SITE_KEY}", {action: "${action}"})
            .then(token => window.postMessage({type: "recaptcha-token", action: "${action}", token}, "${DIALOG_BASE_URL}"));
        }
      }

      window.addEventListener('message', handleReCaptchaActionMessage, false);
    `;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const reCaptchaOnPress = useCallback(async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      window.postMessage({ type: "recaptcha-action", action }, DIALOG_BASE_URL);

      const timeoutId = setTimeout(() => {
        window.removeEventListener("message", handleReCaptchaTokenMessage);
        onPress().then(resolve).catch(reject);
      }, 10000);

      const handleReCaptchaTokenMessage = (event: MessageEvent): void => {
        if (
          event.origin === DIALOG_BASE_URL &&
          event.data?.type === "recaptcha-token" &&
          typeof event.data.token === "string"
        ) {
          const token: string = event.data.token;

          clearTimeout(timeoutId); // Clear the timeout
          window.removeEventListener("message", handleReCaptchaTokenMessage); // Clean up the event listener

          onPress(token)
            .then(resolve)
            .catch(async () => onPress().then(resolve).catch(reject));
        }
      };

      window.addEventListener("message", handleReCaptchaTokenMessage);
    });
  }, [action, onPress]);

  return (
    <AsyncButton
      onPress={reCaptchaOnPress}
      isDisabled={isDisabled}
      isAlert={isAlert}
      buttonText={buttonText}
    />
  );
};
