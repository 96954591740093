import { Text, View } from "react-native";
import { BirthdayPicker, isBirthdayValid } from "./BirthdayPicker";
import styles from "../styles";
import { AppHeader } from "../AppHeader";
import { useEffect, useState } from "react";
import { AsyncButton } from "../common/Button";
import { useDialogApiContext } from "../DialogApiContext";
import { ErrorMessage } from "../common/ErrorMessage";
import { useAuthContext } from "../AuthContext";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { type AddBirthdayParamList } from "./AddBirthdayStack";

type Props = NativeStackScreenProps<AddBirthdayParamList, "AddBirthdayScreen">;

export const AddBirthdayScreen: React.FC<Props> = ({ navigation }) => {
  const { setIsAccountCreationComplete } = useAuthContext();

  const { dialogClient } = useDialogApiContext();

  const [birthdate, setBirthdate] = useState<string>(
    new Date().toISOString().slice(0, 10),
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    setErrorMessage(undefined);
  }, [birthdate]);

  const saveBirthdate = async (): Promise<void> => {
    setErrorMessage(undefined);

    if (!isBirthdayValid(birthdate)) {
      setErrorMessage("You must be at least 13 years old to create an account");
      return;
    }

    try {
      setIsAccountCreationComplete(false);
      await dialogClient.updateBirthdate(birthdate);
      navigation.navigate("CreateAccountCompleteScreen");
    } catch (error) {
      const msg =
        error.response.data.code === "INVALID_BIRTHDATE"
          ? "You must be at least 13 years old to create an account"
          : "An error occurred";
      setErrorMessage(msg);
    }
  };

  return (
    <View style={styles.authFlowContainer}>
      <View style={{ borderBottomColor: "black", borderBottomWidth: 1 }}>
        <AppHeader />
      </View>
      <Text style={styles.authFlowHeader}>
        Enter your date of birth to complete sign up
      </Text>
      <Text style={{ fontSize: 16, textAlign: "center", margin: 5 }}>
        {"(This will not be visible to others)"}
      </Text>
      <BirthdayPicker onChange={setBirthdate} />
      <View style={{ marginTop: 10 }}>
        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      </View>
      <View style={{ marginTop: 10 }}>
        <AsyncButton onPress={saveBirthdate} buttonText="Save" />
      </View>
    </View>
  );
};
