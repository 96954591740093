import { type IMessage } from "react-native-gifted-chat";
import {
  ConversationStatus,
  type ConversationSearchItem,
  ConversationUserRole,
  type ConversationMessageResponse,
  type ConversationUserResponse,
} from "../client/DialogClientModels";

export const makeMyDialogTopicText = (
  item: ConversationSearchItem,
  currentUserId: string,
): string => {
  if (
    item.status === ConversationStatus.OPEN ||
    item.status === ConversationStatus.REMOVED
  ) {
    return `I want a dialog with ${item.title}`;
  }

  const user = item.users.find(({ userId }) => userId === currentUserId);
  const otherUser = item.users.find(({ userId }) => userId !== currentUserId);

  const members =
    user?.role === ConversationUserRole.CREATOR
      ? `Me and ${otherUser?.name}`
      : `${otherUser?.name} and Me`;
  return `${members}, ${item.title}`;
};

export const makePublicDialogTopicText = (
  item: ConversationSearchItem,
): string => {
  const creator = item.users.find(
    ({ role }) => role === ConversationUserRole.CREATOR,
  );
  const responder = item.users.find(
    ({ role }) => role === ConversationUserRole.RESPONDER,
  );

  return `${creator?.name} and ${responder?.name}, ${item.title}`;
};

export const makeGiftedChatMessage = (
  message: ConversationMessageResponse,
  users: ConversationUserResponse[],
  dialogTitle?: string,
): IMessage => {
  const { messageId: _id, content: text, userId, createdAt } = message;

  const conversationUser = users.find((user) => user.userId === userId);

  const name =
    conversationUser?.role === ConversationUserRole.RESPONDER && dialogTitle
      ? conversationUser?.name + `, ${dialogTitle}`
      : conversationUser?.name;

  const user = {
    _id: userId,
    name,
    avatar:
      conversationUser?.pictureUrl &&
      getThumbnailUrl(conversationUser.pictureUrl),
  };

  return {
    _id,
    text,
    createdAt,
    user,
  };
};

export const getThumbnailUrl = (url: string): string => {
  if (!url.startsWith("https://dialog-profile-picture")) {
    return url;
  }

  if (url.includes("-thumbnail.")) {
    return url;
  }

  const urlParts = url.split(".");
  const extension = urlParts.pop();
  return `${urlParts.join(".")}-thumbnail.${extension}`;
};
