import { ScrollView } from "react-native";
import Markdown from "react-native-markdown-display";
import { useAuthContext } from "../AuthContext";
import { useEffect } from "react";

const doc = `
# Rules and Policies

Welcome to Dialog! To ensure a positive and respectful experience for all users, we have established the following rules and policies:

## 1. Respectful Conduct:

All users are expected to engage in conversations with respect, empathy, and open-mindedness. Discriminatory language, hate speech, harassment, or any form of disrespectful behavior will not be tolerated.

## 2. Privacy and Confidentiality:

Users must respect the privacy and confidentiality of others. Sharing personal information without consent or disclosing private conversations is strictly prohibited.

## 3. Authenticity:

Users must provide accurate information about themselves and their experiences. Impersonation, deception, or misrepresentation of identity is not allowed.

## 4. Content Guidelines:

- Users are solely responsible for the content they publish or share on the App.
- Content must not violate any laws or infringe upon the rights of others.
- Content that is unlawful, defamatory, obscene, harmful, or otherwise objectionable is not permitted.

## 5. Consent for Publication:

Before publishing a conversation between users, both parties must consent to its publication. Dialog respects the privacy and autonomy of its users and will not publish any content without explicit consent.

## 6. Reporting and Moderation:

If you encounter any violation of these rules and policies, please report it to our moderation team. We will investigate all reports promptly and take appropriate action, which may include warning, suspension, or termination of accounts.

## 7. Community Guidelines:

- Engage in constructive dialogue and listen to others' perspectives.
- Be open to learning and understanding different experiences and viewpoints.
- Foster a welcoming and inclusive environment for all users.

## 8. Compliance:

By using the Dialog App, you agree to abide by these rules and policies. Failure to comply may result in disciplinary action, including account suspension or termination.

## 9. Contact Us:

If you have any questions or concerns about these rules and policies, please contact us at:

support@dialogapp.co

## 10. Updates:

These rules and policies may be updated or amended from time to time. We encourage users to review them periodically to stay informed about any changes.

Thank you for being a part of the Dialog community!

This document was last updated on March 14, 2024.
`;

export const RulesAndPoliciesScreen: React.FC = () => {
  const { requiresLogin, setRequiresLogin } = useAuthContext();

  useEffect(() => {
    if (requiresLogin) {
      setRequiresLogin(false);
    }
  }, []);

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Markdown style={{ body: { padding: 20 } }}>{doc}</Markdown>
    </ScrollView>
  );
};
