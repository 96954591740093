import axios from "axios";
import type React from "react";
import { useState, useEffect } from "react";
import { Image, View, Pressable } from "react-native";
import * as ImagePicker from "expo-image-picker";
import { useAuthContext } from "../../AuthContext";
import { Feather } from "@expo/vector-icons";
import { ProfileEditButtons } from "./ProfileEditButtons";
import { useDialogApiContext } from "../../DialogApiContext";
import { ErrorMessage } from "../../common/ErrorMessage";

export type ProfileImageStatus =
  | "NO_IMAGE"
  | "UPLOADING"
  | "UPLOADED"
  | "ERROR";

type Props = {
  onChangeStatus?: (status: ProfileImageStatus) => void;
};

export const ProfileImage: React.FC<Props> = ({
  onChangeStatus: onStatusChange,
}) => {
  const { user } = useAuthContext();

  const { dialogClient } = useDialogApiContext();

  const [imageUri, setImageUri] = useState<string | undefined>(undefined);
  const [newImageUri, setNewImageUri] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<ProfileImageStatus>("NO_IMAGE");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    if (!imageUri) {
      setImageUri(user?.pictureUrl);
    }
  }, [user?.pictureUrl]);

  useEffect(() => {
    if (onStatusChange) {
      onStatusChange(status);
    }
  }, [status]);

  const pickImage = async (): Promise<void> => {
    try {
      setErrorMessage(undefined);
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        setNewImageUri(result.assets[0].uri);
      }
    } catch (error) {
      setErrorMessage("Error loading image");
    }
  };

  const pickImageSync = (): void => {
    pickImage().catch(console.error);
  };

  const saveImage = async (): Promise<void> => {
    setErrorMessage(undefined);
    if (newImageUri) {
      try {
        setStatus("UPLOADING");
        const image = await fetch(newImageUri);
        const imageBlob = await image.blob();

        const { signedUrl, key } =
          await dialogClient.getProfilePictureUploadUrl();

        await axios.put(signedUrl, imageBlob);

        const { pictureUrl } = await dialogClient.saveProfilePictureUpload(key);

        setImageUri(pictureUrl);
        setNewImageUri(undefined);
        setStatus("UPLOADED");
      } catch (error) {
        setErrorMessage("Error saving image");
        setStatus("ERROR");
      }
    }
  };

  const ImageDisplay: React.FC = () => {
    const uri = newImageUri ?? imageUri;
    if (uri) {
      return (
        <Image
          source={{ uri }}
          style={{ width: 200, height: 200, borderRadius: 100 }}
        />
      );
    }

    return (
      <Pressable
        onPress={pickImageSync}
        style={{
          width: 200,
          height: 200,
          borderRadius: 100,
          backgroundColor: "lightgray",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Feather name="camera" size={40} color="blue" />
      </Pressable>
    );
  };

  return (
    <View style={{ flexDirection: "column", alignItems: "center" }}>
      <ImageDisplay />
      <ProfileEditButtons
        editText={imageUri ? "Edit Profile Photo" : "Add Profile Photo"}
        isEditing={newImageUri !== undefined}
        onEdit={pickImageSync}
        onSave={saveImage}
        onCancel={() => {
          setErrorMessage(undefined);
          setNewImageUri(undefined);
        }}
      />
      {errorMessage && (
        <View style={{ marginTop: 10 }}>
          <ErrorMessage errorMessage={errorMessage} />
        </View>
      )}
    </View>
  );
};
