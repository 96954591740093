import { Pressable, Text, View } from "react-native";
import styles from "../styles";
import { useEffect, useState } from "react";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { type OpenDialogStackParamList } from "./OpenDialogsStack";
import { type BottomTabNestedStackScreenProps } from "../navigation/NavigationHelper";
import { Banner, BannerType } from "../common/Banner";
import { OpenDialogReportMenu } from "./menu/OpenDialogReportMenu";
import { useAuthContext } from "../AuthContext";
import { ReCaptchaButton } from "../common/Button";
import { useDialogApiContext } from "../DialogApiContext";
import { ErrorMessage } from "../common/ErrorMessage";
import { HeaderTitle } from "../common/HeaderTitle";
import { SignInToContinue } from "../common/SignInToContinue";

type Props = NativeStackScreenProps<
  OpenDialogStackParamList,
  "OpenDialogScreen"
>;

export const OpenDialogScreen: React.FC<
  BottomTabNestedStackScreenProps<Props>
> = ({ route, navigation }) => {
  const { title } = route.params;

  const [isReported, setIsReported] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const { user, fetchUserStatus } = useAuthContext();

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => <HeaderTitle title={title} />,
      headerRight: () => (
        <View style={styles.topMenuContainer}>
          {!isReported && (
            <OpenDialogReportMenu title={title} setIsReported={setIsReported} />
          )}
        </View>
      ),
    });
  }, [navigation, title]);

  const { dialogClient } = useDialogApiContext();

  const handleSubmit = async (reCaptchaToken?: string): Promise<void> => {
    try {
      const { conversationTopicId, conversationId } =
        await dialogClient.respondToConversationWithTitle(
          title,
          reCaptchaToken,
        );

      setErrorMessage(undefined);

      navigation.navigate("My Dialogs", {
        screen: "MyDialogScreen",
        params: { conversationTopicId, conversationId, title },
      });
    } catch (error) {
      if (error.response.data.code === "CONVERSATION_LIMIT_REACHED") {
        const message =
          "You have reached your daily dialog limit." +
          "\nNew accounts may start or join 2 dialogs in a 24 hour period." +
          "\nAccounts at least one week old may start or join 10 dialogs every 24 hours.";

        setErrorMessage(message);
      } else {
        setErrorMessage(
          "This dialog is not available. Please try another dialog.",
        );
      }
    }
  };

  const handleReject = (): void => {
    navigation.navigate("Open Dialogs", {
      screen: "OpenDialogListScreen",
      params: { slideUp: undefined },
    });
  };

  const responseText = `Are you ${title}?\nDo you want to have a dialog about it?`;

  if (fetchUserStatus === "PENDING") {
    return <></>;
  }

  return user && fetchUserStatus === "COMPLETE" ? (
    <View style={{ flex: 1 }}>
      {isReported && (
        <Banner
          message="You have reported this dialog. We will review it to determine the appropriate next steps. No further action is required from you."
          type={BannerType.ALERT}
        />
      )}
      <View style={styles.dialogResponseContainer}>
        <Text style={styles.dialogResponseTitle}>{responseText}</Text>
        <ReCaptchaButton
          action="join_dialog"
          isDisabled={() => errorMessage !== undefined}
          onPress={handleSubmit}
          buttonText="Yes"
        />
        <Pressable onPress={handleReject} style={{ marginTop: 10 }}>
          <Text style={styles.alertLinkText}>No</Text>
        </Pressable>
        {errorMessage && (
          <View style={{ marginTop: 20, maxWidth: 1000, alignSelf: "center" }}>
            <ErrorMessage errorMessage={errorMessage} />
          </View>
        )}
      </View>
    </View>
  ) : (
    <SignInToContinue action={"join a dialog"} />
  );
};
