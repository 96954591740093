import { type ReactNode } from "react";
import type React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { Hub } from "aws-amplify/utils";
import * as Auth from "@aws-amplify/auth";
import { signOut as amplifySignOut } from "@aws-amplify/auth";

interface AuthContextProps {
  children: ReactNode;
}

interface User {
  userId: string;
  birthdate?: string;
  name?: string;
  phoneNumber?: string;
  email?: string;
  pictureUrl?: string;
}

interface AuthContextType {
  user: User | undefined;
  fetchUser: () => Promise<void>;
  fetchUserStatus: UserFetchStatus;
  signOut: () => Promise<void>;
  requiresLogin: boolean;
  setRequiresLogin: (requiresLogin: boolean) => void;
  isAccountCreationComplete: boolean;
  setIsAccountCreationComplete: (isAccountCreationComplete: boolean) => void;
}

type UserFetchStatus =
  | "PENDING"
  | "COMPLETE"
  | "ERROR"
  | "MISSING_BIRTHDATE"
  | "SUSPENDED";

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [fetchUserStatus, setFetchUserStatus] =
    useState<UserFetchStatus>("PENDING");
  const [requiresLogin, setRequiresLogin] = useState(false);
  const [isAccountCreationComplete, setIsAccountCreationComplete] =
    useState(true);

  const fetchUser = async (): Promise<void> => {
    try {
      const currentUser = await Auth.getCurrentUser();
      const attributes = await Auth.fetchUserAttributes();
      const session = await Auth.fetchAuthSession();
      const userGroups =
        (session.tokens?.idToken?.payload["cognito:groups"] as string[]) ?? [];

      setUser({
        userId: currentUser.username,
        name: attributes["custom:customName"] ?? attributes.name,
        phoneNumber: attributes.phone_number,
        email: attributes.email,
        pictureUrl: attributes["custom:customPicture"] ?? attributes.picture,
        birthdate: attributes.birthdate,
      });
      if (userGroups.includes("Suspended")) {
        setFetchUserStatus("SUSPENDED");
      } else {
        setFetchUserStatus(
          attributes.birthdate ? "COMPLETE" : "MISSING_BIRTHDATE",
        );
      }
    } catch (error) {
      setUser(undefined);
      setFetchUserStatus("ERROR");
    }
  };

  useEffect(() => {
    const hubListener = (data: any): void => {
      const { payload } = data;
      if (payload.event === "signedIn") {
        fetchUser().catch(console.error); // Fetch user details after sign-in
      } else if (payload.event === "signedOut") {
        setUser(undefined);
      }
    };

    Hub.listen("auth", hubListener);
  }, []);

  useEffect(() => {
    fetchUser().catch(console.error);
  }, [isAccountCreationComplete]);

  const signOut = async (): Promise<void> => {
    try {
      await amplifySignOut();
      setUser(undefined);
    } catch (error) {
      console.error("Sign-out error:", error);
    }
  };

  const contextValue: AuthContextType = {
    user,
    fetchUser,
    fetchUserStatus,
    signOut,
    requiresLogin,
    setRequiresLogin,
    isAccountCreationComplete,
    setIsAccountCreationComplete,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
