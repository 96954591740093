import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { DialogClient } from "./client/DialogClient";
import { fetchAuthSession } from "@aws-amplify/auth";
import { type WebsocketMessage } from "./client/DialogClientModels";

const DIALOG_WEBSOCKET_BASE_URL = process.env.DIALOG_WEBSOCKET_BASE_URL ?? "";

const MAX_WEBSOCKET_RETRIES = 5;
const BASE_WEBSOCKET_RETRY_DELAY_MS = 1000; // 1 second
const WEBSOCKET_RECONNECT_INTERVAL_MS = 10 * 60 * 1000; // 10 minutes

interface DialogApiContextProps {
  children: ReactNode;
}

interface DialogApiContextType {
  dialogClient: DialogClient;
  latestWebsocketMessage?: WebsocketMessage;
}

const DialogApiContext = createContext<DialogApiContextType | undefined>(
  undefined,
);

export const DialogApiProvider: React.FC<DialogApiContextProps> = ({
  children,
}) => {
  const [latestWebsocketMessage, setLatestWebsocketMessage] = useState<
    WebsocketMessage | undefined
  >(undefined);

  let websocket: WebSocket | undefined;
  let websocketConnectionVerifiedTime: Date | undefined;
  let websocketRetryIntervalId: NodeJS.Timeout | undefined;
  let websocketRetryCount = 0;

  const initWebsocket = async (): Promise<void> => {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken?.toString();

    if (accessToken) {
      websocket = new WebSocket(
        `${DIALOG_WEBSOCKET_BASE_URL}?accessToken=${accessToken}`,
      );

      websocket.onopen = () => {
        websocketRetryCount = 0;
        websocketConnectionVerifiedTime = new Date();
      };

      websocket.onclose = async () => {
        if (websocketRetryCount < MAX_WEBSOCKET_RETRIES) {
          const delay =
            Math.pow(2, websocketRetryCount) * BASE_WEBSOCKET_RETRY_DELAY_MS;

          setTimeout(() => {
            websocketRetryCount++;
            initWebsocket().catch(console.error);
          }, delay);
        }
      };

      websocket.onmessage = (event) => {
        websocketConnectionVerifiedTime = new Date();
        const messageString: string = event.data;
        const message: WebsocketMessage = JSON.parse(messageString);
        setLatestWebsocketMessage(message);
      };
    }
  };

  useEffect(() => {
    initWebsocket().catch(console.error);

    // Every 10 minutes, check if the websocket connection has been opened or received a message in the past 10 minutes
    // The websocket server has an idle timeout of 10 minutes, so no activity implies that the connection has been closed
    websocketRetryIntervalId = setInterval(() => {
      if (
        !websocketConnectionVerifiedTime ||
        websocketConnectionVerifiedTime <
          new Date(Date.now() - WEBSOCKET_RECONNECT_INTERVAL_MS)
      ) {
        initWebsocket().catch(console.error);
      }
    }, WEBSOCKET_RECONNECT_INTERVAL_MS);

    return () => {
      if (websocket) {
        websocket.onclose = null;
        websocket.close();
      }

      if (websocketRetryIntervalId) {
        clearInterval(websocketRetryIntervalId);
      }
    };
  }, []);

  const contextValue = {
    dialogClient: new DialogClient(),
    latestWebsocketMessage,
  };

  return (
    <DialogApiContext.Provider value={contextValue}>
      {children}
    </DialogApiContext.Provider>
  );
};

export const useDialogApiContext = (): DialogApiContextType => {
  const context = useContext(DialogApiContext);
  if (!context) {
    throw new Error(
      "useDialogApiContext must be used within a DialogApiProvider",
    );
  }
  return context;
};
