import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import type React from "react";
import { Feather } from "@expo/vector-icons";
import {
  type OpenDialogStackParamList,
  OpenDialogsStack,
} from "../open-dialogs/OpenDialogsStack";
import {
  type MyDialogStackParamList,
  MyDialogsStack,
} from "../my-dialogs/MyDialogsStack";
import { StartDialogScreen } from "../start-dialog/StartDialogScreen";
import {
  type PublicDialogStackParamList,
  PublicDialogsStack,
} from "../public-dialogs/PublicDialogsStack";
import { type NavigatorScreenParams } from "@react-navigation/native";
import { MenuStack, type MenuStackParamList } from "../menu/MenuStack";

export type BottomTabParamList = {
  "Open Dialogs": NavigatorScreenParams<OpenDialogStackParamList>;
  "My Dialogs": NavigatorScreenParams<MyDialogStackParamList>;
  "Start Dialog": undefined;
  "Public Dialogs": NavigatorScreenParams<PublicDialogStackParamList>;
  More: NavigatorScreenParams<MenuStackParamList>;
};

const Tab = createBottomTabNavigator<BottomTabParamList>();

export const BottomTabNavigator: React.FC = () => {
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ color, size }) => {
          let iconName:
            | "home"
            | "message-square"
            | "plus"
            | "globe"
            | "menu"
            | undefined;

          if (route.name === "Open Dialogs") {
            iconName = "home";
          } else if (route.name === "My Dialogs") {
            iconName = "message-square";
          } else if (route.name === "Start Dialog") {
            iconName = "plus";
          } else if (route.name === "Public Dialogs") {
            iconName = "globe";
          } else if (route.name === "More") {
            iconName = "menu";
          }

          return <Feather name={iconName} size={size} color={color} />;
        },
        // tabBarShowLabel: route.name === "Menu",
      })}
    >
      <Tab.Screen
        name="Open Dialogs"
        component={OpenDialogsStack}
        listeners={({ navigation }) => ({
          tabPress: (event) => {
            event.preventDefault();
            navigation.navigate("Open Dialogs", {
              screen: "OpenDialogListScreen",
            });
          },
        })}
        options={{
          headerShown: false,
        }}
      />
      <Tab.Screen
        name="My Dialogs"
        component={MyDialogsStack}
        listeners={({ navigation }) => ({
          tabPress: (event) => {
            event.preventDefault();
            navigation.navigate("My Dialogs", {
              screen: "MyDialogListScreen",
            });
          },
        })}
        options={{
          headerShown: false,
        }}
      />
      <Tab.Screen
        name="Start Dialog"
        component={StartDialogScreen}
        options={{
          headerShown: false,
        }}
      />
      <Tab.Screen
        name="Public Dialogs"
        component={PublicDialogsStack}
        listeners={({ navigation }) => ({
          tabPress: (event) => {
            // Prevent default action
            event.preventDefault();
            navigation.navigate("Public Dialogs", {
              screen: "PublicDialogListScreen",
            });
          },
        })}
        options={{
          headerShown: false,
        }}
      />
      <Tab.Screen
        name="More"
        component={MenuStack}
        listeners={({ navigation }) => ({
          tabPress: (event) => {
            event.preventDefault();
            navigation.navigate("More", {
              screen: "MenuScreen",
            });
          },
        })}
        options={{
          headerShown: false,
        }}
      />
    </Tab.Navigator>
  );
};
