import { signUp, autoSignIn } from "aws-amplify/auth";
import { useState } from "react";
import { Text, View, TextInput, Pressable } from "react-native";
import styles from "../styles";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { type CreateAccountParamList } from "./CreateAccountStack";
import { useAuthenticator } from "@aws-amplify/ui-react-native";
import { useAuthContext } from "../AuthContext";
import { AsyncButton } from "../common/Button";
import { PasswordInput, UsernameInput } from "../common/AuthInput";
import { AppHeader } from "../AppHeader";
import { ErrorMessage } from "../common/ErrorMessage";
import { BirthdayPicker, isBirthdayValid } from "./BirthdayPicker";
import { A } from "@expo/html-elements";

const DIALOG_BASE_URL = process.env.DIALOG_BASE_URL ?? "";

type Props = NativeStackScreenProps<
  CreateAccountParamList,
  "CreateAccountScreen"
>;

export const CreateAccountWithEmailScreen: React.FC<Props> = ({
  navigation,
}) => {
  const [name, setName] = useState<string | undefined>(undefined);
  const [birthdate, setBirthdate] = useState<string>(
    new Date().toISOString().slice(0, 10),
  );
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const { toSignIn } = useAuthenticator();

  const { setIsAccountCreationComplete } = useAuthContext();

  const isDisabled = (): boolean => {
    return !(name && birthdate && username && password && hasAcceptedTerms);
  };

  const validateInputs = (): boolean => {
    if (!isBirthdayValid(birthdate)) {
      setErrorMessage("You must be at least 13 years old to create an account");
      return false;
    }

    if (password && password.length < 8) {
      setErrorMessage("Password must be at least 8 characters long");
      return false;
    }

    setErrorMessage(undefined);
    return true;
  };

  const createAccount = async (): Promise<void> => {
    if (!validateInputs()) {
      return;
    }

    if (name && birthdate && username && password) {
      try {
        const { nextStep } = await signUp({
          username,
          password,
          options: {
            autoSignIn: true,
            userAttributes: {
              name,
              birthdate,
            },
          },
        });

        switch (nextStep.signUpStep) {
          case "CONFIRM_SIGN_UP":
            navigation.navigate("ConfirmAccountScreen", {
              username,
              destination: nextStep.codeDeliveryDetails.destination,
              deliveryMedium: nextStep.codeDeliveryDetails.deliveryMedium,
            });
            break;
          case "COMPLETE_AUTO_SIGN_IN":
            setIsAccountCreationComplete(false);
            await autoSignIn();
            break;
          default:
        }
      } catch (error) {
        if (
          error.name === "InvalidParameterException" ||
          error.name === "InvalidPasswordException" ||
          error.name === "UsernameExistsException"
        ) {
          const msg: string = error.message;
          setErrorMessage(msg);
        } else {
          setErrorMessage("An error occurred");
        }
      }
    }
  };

  return (
    <View style={styles.authFlowContainer}>
      <View style={{ borderBottomColor: "black", borderBottomWidth: 1 }}>
        <AppHeader />
      </View>
      <Text style={styles.authFlowHeader}>Create Account</Text>
      <TextInput
        placeholder="What would you like to be called?"
        placeholderTextColor="gray"
        value={name ?? ""}
        onChangeText={setName}
        maxLength={50}
        style={{
          ...styles.authTextInput,
          marginTop: 40,
        }}
      />
      <Text style={styles.authInputLabel}>Name</Text>
      <View style={styles.privateProfileContainer}>
        <Text style={{ fontSize: 18, textAlign: "center" }}>
          The following information will not be visible to others
        </Text>
        <UsernameInput username={username} setUsername={setUsername} />
        <BirthdayPicker onChange={setBirthdate} />
        <PasswordInput password={password} setPassword={setPassword} />
      </View>
      <View style={{ marginTop: 10 }}>
        <ErrorMessage errorMessage={errorMessage} />
      </View>
      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}
      >
        <Pressable
          onPress={() => {
            setHasAcceptedTerms(!hasAcceptedTerms);
          }}
          style={{
            height: 16,
            width: 16,
            borderWidth: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 12,
              width: 12,
              backgroundColor: hasAcceptedTerms ? "blue" : undefined,
            }}
          />
        </Pressable>
        <Text style={{ fontSize: 16, marginLeft: 10 }}>
          {"I agree to the Dialog "}
          <A
            href={`${DIALOG_BASE_URL}/privacy-policy`}
            target="_blank"
            style={styles.linkText}
          >
            Privacy Policy
          </A>
          {", "}
          <A
            href={`${DIALOG_BASE_URL}/terms-of-service`}
            target="_blank"
            style={styles.linkText}
          >
            Terms of Service
          </A>
          {", and "}
          <A
            href={`${DIALOG_BASE_URL}/rules-and-policies`}
            target="_blank"
            style={styles.linkText}
          >
            Rules & Policies
          </A>
        </Text>
      </View>
      <Text style={{ fontSize: 16, marginHorizontal: 10, marginVertical: 5 }}>
        {"This site is protected by reCAPTCHA and the Google "}
        <A href="https://policies.google.com/privacy" style={styles.linkText}>
          Privacy Policy
        </A>
        {" and "}
        <A href="https://policies.google.com/terms" style={styles.linkText}>
          Terms of Service
        </A>
        {" apply."}
      </Text>
      <View style={{ marginTop: 20 }}>
        <AsyncButton
          isDisabled={isDisabled}
          onPress={createAccount}
          buttonText="Create Account"
        />
      </View>
      <Pressable onPress={toSignIn} style={{ marginTop: 10 }}>
        <Text style={styles.linkText}>Sign In</Text>
      </Pressable>
    </View>
  );
};
