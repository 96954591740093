import { Text, View } from "react-native";
import { SlideUpMenuButton } from "../../common/SlideUpMenuButton";
import { Feather } from "@expo/vector-icons";
import styles from "../../styles";
import {
  type ConversationMetadataResponse,
  ConversationReportContext,
  type ConversationUserResponse,
} from "../../client/DialogClientModels";
import { useAuthContext } from "../../AuthContext";
import { useState } from "react";
import { AsyncButton } from "../../common/Button";
import { useDialogApiContext } from "../../DialogApiContext";

export const MyDialogReportMenu: React.FC<{
  metadata: ConversationMetadataResponse;
  users: ConversationUserResponse[];
  fetchConversation: () => Promise<void>;
}> = ({ metadata, users, fetchConversation }) => {
  const { dialogClient } = useDialogApiContext();
  const userId = useAuthContext().user?.userId;

  const [isOpen, setIsOpen] = useState(false);

  const otherUser = users.find((user) => user.userId !== userId);

  const ReportButton: React.FC = () => {
    return (
      <View style={styles.topMenuButton}>
        <Feather
          name="alert-triangle"
          size={20}
          style={styles.topMenuAlertIcon}
        />
        <Text style={styles.topMenuAlertText}>Report</Text>
      </View>
    );
  };

  const SlideUp: React.FC = () => {
    const onPress = async (): Promise<void> => {
      await dialogClient.reportConversation(
        metadata.conversationTopicId,
        metadata.conversationId,
        ConversationReportContext.PRIVATE,
      );
      await fetchConversation();
      setIsOpen(false);
    };

    const messageText = `Press "Report" to report ${otherUser?.name} for posting content that violates dialog's rules.`;

    return (
      <View style={styles.slideUpContentContainer}>
        <Text style={styles.slideUpText}>{messageText}</Text>
        <AsyncButton onPress={onPress} isAlert buttonText="Report" />
      </View>
    );
  };

  return (
    <SlideUpMenuButton
      triggerComponent={<ReportButton />}
      contentComponent={<SlideUp />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
