import {
  NavigationContainer,
  type LinkingOptions,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as Linking from "expo-linking";
import { AddBirthdayScreen } from "./AddBirthdayScreen";
import { CreateAccountCompleteScreen } from "./CreateAccountCompleteScreen";
import { RedirectScreen } from "./RedirectScreen";

export type AddBirthdayParamList = {
  AddBirthdayScreen: undefined;
  CreateAccountCompleteScreen: undefined;
  RedirectScreen: undefined;
};

const prefix = Linking.createURL("/");

const config = {
  screens: {
    AddBirthdayScreen: "add-dob",
    CreateAccountCompleteScreen: "create-account-complete",
    // By using the root path, we automatically navigate to the home screen after
    // transitioning to the logged-in state
    RedirectScreen: "/",
  },
};

const linking: LinkingOptions<AddBirthdayParamList> = {
  prefixes: [prefix],
  config,
};

const Stack = createNativeStackNavigator<AddBirthdayParamList>();

export const AddBirthdayStack: React.FC = () => {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName="AddBirthdayScreen">
        <Stack.Screen
          name="AddBirthdayScreen"
          component={AddBirthdayScreen}
          options={{
            title: "Add Date of Birth",
            headerShown: false,
            headerBackVisible: false,
          }}
        />
        <Stack.Screen
          name="CreateAccountCompleteScreen"
          component={CreateAccountCompleteScreen}
          options={{
            title: "Account Creation Complete",
            headerShown: false,
            headerBackVisible: false,
          }}
        />
        <Stack.Screen
          name="RedirectScreen"
          component={RedirectScreen}
          options={{
            title: "Account Creation Complete",
            headerShown: false,
            headerBackVisible: false,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};
