import { Text, View } from "react-native";
import { SlideUpMenuButton } from "../../common/SlideUpMenuButton";
import { Feather } from "@expo/vector-icons";
import styles from "../../styles";
import { ConversationReportContext } from "../../client/DialogClientModels";
import { useState } from "react";
import { AsyncButton } from "../../common/Button";
import { useDialogApiContext } from "../../DialogApiContext";

// TODO: Combine with MyDialogReportMenu
export const OpenDialogReportMenu: React.FC<{
  title: string;
  setIsReported: (reported: boolean) => void;
}> = ({ title, setIsReported }) => {
  const { dialogClient } = useDialogApiContext();

  const [isOpen, setIsOpen] = useState(false);

  const ReportButton: React.FC = () => {
    return (
      <View style={styles.topMenuButton}>
        <Feather
          name="alert-triangle"
          size={20}
          style={styles.topMenuAlertIcon}
        />
        <Text style={styles.topMenuAlertText}>Report</Text>
      </View>
    );
  };

  const SlideUp: React.FC = () => {
    const onPress = async (): Promise<void> => {
      await dialogClient.reportConversationWithTitle(
        title,
        ConversationReportContext.TOPIC_TITLE,
      );
      setIsReported(true);
      setIsOpen(false);
    };

    const messageText =
      'Press "Report" to report this open dialog for violating dialog\'s rules.';

    return (
      <View style={styles.slideUpContentContainer}>
        <Text style={styles.slideUpText}>{messageText}</Text>
        <AsyncButton onPress={onPress} isAlert buttonText="Report" />
      </View>
    );
  };

  return (
    <SlideUpMenuButton
      triggerComponent={<ReportButton />}
      contentComponent={<SlideUp />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
