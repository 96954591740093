import { Image, Pressable, Text, View } from "react-native";
import { SlideUpMenu } from "../common/SlideUpMenu";
import styles from "../styles";
import { useAuthContext } from "../AuthContext";
import { Button } from "../common/Button";
import type React from "react";

export const WelcomeSlideUp: React.FC<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  const { fetchUserStatus, user, setRequiresLogin } = useAuthContext();

  const onSignUpPress = (): void => {
    setIsOpen(false);
    // timeout allows slide up to close
    setTimeout(() => {
      setRequiresLogin(true);
    }, 500);
  };

  const Buttons: React.FC = () => {
    if (fetchUserStatus === "PENDING") {
      return <></>;
    }

    if (user) {
      return (
        <Button
          buttonText="Get Started"
          onPress={() => {
            setIsOpen(false);
          }}
        />
      );
    }

    return (
      <View>
        <Button buttonText="Sign Up to Get Started" onPress={onSignUpPress} />
        <Pressable
          onPress={() => {
            setIsOpen(false);
          }}
          style={{
            flexDirection: "row",
            alignItems: "center",
            padding: 15,
          }}
        >
          <Text style={styles.linkText}>Browse dialogs without signing up</Text>
        </Pressable>
      </View>
    );
  };

  const MenuContent: React.FC = () => {
    return (
      <View style={styles.slideUpContainer}>
        <Image
          source={require("../../assets/chairs.png")}
          resizeMode={"contain"}
          style={{
            position: "absolute",
            aspectRatio: 1,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0.1,
          }}
        />
        <Text
          style={{
            fontSize: 32,
            marginBottom: 20,
            marginTop: 20,
            zIndex: 1,
          }}
        >
          Welcome to Dialog!
        </Text>
        <Text
          style={{
            textAlign: "center",
            maxWidth: 500,
            fontSize: 18,
            fontWeight: "400",
            lineHeight: 24,
            zIndex: 1,
            marginHorizontal: 20,
            marginBottom: 30,
          }}
        >
          {'Dialog is a place for one-on-one conversations ("dialogs") between people with different experiences, opinions, or identities.' +
            "\n\nYou can start a new dialog with a Democrat, a Republican, a veteran, a blind person... " +
            "anyone you'd like to learn more about. Or you can respond to an open dialog if you fit the description." +
            "\n\nDialog fosters good-faith, open-minded communication by limiting dialogs to two people " +
            "and by keeping dialogs private unless both people chose to share them publicly."}
        </Text>
        <Buttons />
      </View>
    );
  };

  return (
    <SlideUpMenu
      triggerComponent={<></>}
      contentComponent={<MenuContent />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
