import { Image, Pressable } from "react-native";
import { signInWithRedirect } from "aws-amplify/auth";
import { useState } from "react";

export const ContinueWithGoogleButton: React.FC<{
  onError?: (error: any) => void;
}> = ({ onError }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const signInWithGoogle = async (): Promise<void> => {
    try {
      setIsDisabled(true);
      await signInWithRedirect({ provider: "Google" });
    } catch (error) {
      onError?.(error);
    } finally {
      setIsDisabled(false);
    }
  };

  const onPress = (): void => {
    signInWithGoogle().catch(console.error);
  };

  return (
    <Pressable
      onPress={onPress}
      disabled={isDisabled}
      style={{
        width: 322,
        height: 68,
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Image
        source={require("../../assets/continue-with-google.png")}
        style={{
          width: 322,
          height: 68,
        }}
      />
    </Pressable>
  );
};
