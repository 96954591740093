import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { CreateAccountScreen } from "./CreateAccountScreen";
import { ConfirmAccountScreen } from "./ConfirmAccountScreen";
import { AddProfilePictureScreen } from "./AddProfilePictureScreen";
import { type AuthDeliveryMedium } from "@aws-amplify/auth/dist/esm/types";
import {
  NavigationContainer,
  type LinkingOptions,
} from "@react-navigation/native";
import * as Linking from "expo-linking";
import { CreateAccountWithEmailScreen } from "./CreateAccountWithEmailScreen";
import { CreateAccountCompleteScreen } from "./CreateAccountCompleteScreen";
import { RedirectScreen } from "./RedirectScreen";

export type CreateAccountParamList = {
  CreateAccountScreen: undefined;
  CreateAccountWithEmailScreen: undefined;
  ConfirmAccountScreen: {
    username: string;
    destination?: string;
    deliveryMedium?: AuthDeliveryMedium;
  };
  AddProfilePictureScreen: undefined;
  CreateAccountCompleteScreen: undefined;
  RedirectScreen: undefined;
};

const prefix = Linking.createURL("/");

const config = {
  screens: {
    CreateAccountScreen: "create-account",
    CreateAccountWithEmailScreen: "create-account/email",
    ConfirmAccountScreen: "confirm-account",
    AddProfilePictureScreen: "add-profile-picture",
    CreateAccountCompleteScreen: "create-account-complete",
    // By using the root path, we automatically navigate to the home screen after
    // transitioning to the logged-in state
    RedirectScreen: "/",
  },
};

const linking: LinkingOptions<CreateAccountParamList> = {
  prefixes: [prefix],
  config,
};

const Stack = createNativeStackNavigator<CreateAccountParamList>();

export const CreateAccountStack: React.FC = () => {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName="CreateAccountScreen">
        <Stack.Screen
          name="CreateAccountScreen"
          component={CreateAccountScreen}
          options={{
            title: "Create Account",
            headerShown: false,
            headerBackVisible: false,
          }}
        />
        <Stack.Screen
          name="CreateAccountWithEmailScreen"
          component={CreateAccountWithEmailScreen}
          options={{
            title: "Create Account with Email",
            headerShown: false,
            headerBackVisible: false,
          }}
        />
        <Stack.Screen
          name="ConfirmAccountScreen"
          component={ConfirmAccountScreen}
          options={{
            title: "Confirm Account",
            headerShown: false,
            headerBackVisible: false,
          }}
        />
        <Stack.Screen
          name="AddProfilePictureScreen"
          component={AddProfilePictureScreen}
          options={{
            title: "Add Profile Picture",
            headerShown: false,
            headerBackVisible: false,
          }}
        />
        <Stack.Screen
          name="CreateAccountCompleteScreen"
          component={CreateAccountCompleteScreen}
          options={{
            title: "Account Creation Complete",
            headerShown: false,
            headerBackVisible: false,
          }}
        />
        <Stack.Screen
          name="RedirectScreen"
          component={RedirectScreen}
          options={{
            title: "Account Creation Complete",
            headerShown: false,
            headerBackVisible: false,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};
