import { StyleSheet } from "react-native";

export default StyleSheet.create({
  appContainer: {
    flexDirection: "column",
    flex: 1,
    // marginVertical: 10,
  },
  appNameContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    borderBottomColor: "lightgray",
    borderBottomWidth: 1,
  },
  DialogListItem: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  profileIcon: {
    fontSize: 60,
    color: "black",
    justifyContent: "center",
  },
  userName: {
    fontSize: 14,
    marginTop: 5, // Adjust spacing between username and icon
    justifyContent: "center",
    textAlign: "center",
  },
  globeIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "blue",
    width: 16,
    height: 16,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  topicText: {
    fontSize: 16,
    fontWeight: "bold",
    flex: 1,
    flexWrap: "wrap",
    marginHorizontal: 12,
  },
  statusIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  chatMessage: {
    padding: 10,
    margin: 5,
    borderRadius: 10,
    maxWidth: "90%",
  },
  startDialogContainer: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  startDialogTitle: {
    fontSize: 24,
    marginBottom: 20,
    marginTop: 50,
  },
  startDialogInput: {
    width: "100%",
    maxWidth: 500,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
    fontSize: 16,
  },
  startDialogButton: {
    backgroundColor: "blue",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  startDialogButtonText: {
    color: "white",
    fontSize: 16,
  },
  dialogResponseContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  dialogResponseTitle: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: "center",
  },
  button: {
    backgroundColor: "blue",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  alertButton: {
    backgroundColor: "red",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  disabledButton: {
    backgroundColor: "gray",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
  },
  openDialogContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  openDialogTitle: {
    fontSize: 24,
    marginBottom: 20,
  },
  topMenuContainer: {
    flexDirection: "row",
    marginRight: 10,
    alignItems: "flex-start",
  },
  topMenuButton: {
    flexDirection: "column",
    alignItems: "center",
    marginHorizontal: 10,
  },
  topMenuIcon: {
    color: "blue",
  },
  topMenuText: {
    fontSize: 10,
    color: "blue",
  },
  topMenuAlertIcon: {
    color: "red",
  },
  topMenuAlertText: {
    fontSize: 10,
    color: "red",
  },
  slideUpContainer: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
  },
  slideUpContentContainer: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  slideUpText: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: "center",
  },
  contextMenuButton: {
    flexDirection: "row",
  },
  searchBarContainer: {
    flexDirection: "row",
    marginHorizontal: 20,
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    maxWidth: 500,
    alignSelf: "center",
  },
  searchBarInput: {
    flex: 1,
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    marginHorizontal: 10,
    marginVertical: 10,
    padding: 5,
    borderRadius: 10,
    paddingRight: 40,
  },
  searchButton: {
    height: 40,
    width: 40,
    backgroundColor: "blue",
    borderRadius: 10,
    padding: 10,
  },
  searchBarClearButton: {
    height: 18,
    width: 18,
    backgroundColor: "gray",
    borderRadius: 9,
    position: "absolute",
    right: 0,
    marginVertical: 30,
    marginRight: 60,
    alignItems: "center",
    justifyContent: "center",
  },
  privateProfileContainer: {
    flexDirection: "column",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 30,
    backgroundColor: "lightgray",
    borderRadius: 10,
    marginHorizontal: 20,
    marginTop: 20,
  },
  authFlowContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  authFlowHeader: {
    fontSize: 30,
    fontWeight: "500",
    marginTop: 50,
    textAlign: "center",
  },
  authTextInput: {
    fontSize: 20,
    borderWidth: 1,
    borderRadius: 5,
    padding: 5,
    borderColor: "gray",
    textAlign: "center",
    marginTop: 20,
    width: 320,
  },
  authInputLabel: { fontSize: 16, marginTop: 5 },
  errorContainer: {
    backgroundColor: "pink",
    padding: 10,
    borderRadius: 5,
  },
  errorText: { color: "darkred", textAlign: "center" },
  linkText: { color: "blue", fontSize: 15, fontWeight: "500" },
  alertLinkText: { color: "red", fontSize: 15, fontWeight: "500" },
  moreMenuLinkText: {
    color: "blue",
    fontSize: 18,
    fontWeight: "400",
    marginLeft: 5,
  },
  aboutImage: {
    maxWidth: 640,
    maxHeight: 480,
    height: "auto",
    aspectRatio: 1,
    borderRadius: 30,
    marginVertical: 10,
  },
});
