import type React from "react";
import { useState, useEffect } from "react";
import { FlatList, View } from "react-native";
import { MyDialogListItem } from "./MyDialogListItem";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { type MyDialogStackParamList } from "./MyDialogsStack";
import {
  ConversationStatus,
  type ConversationSearchItem,
} from "../client/DialogClientModels";
import { useAuthContext } from "../AuthContext";
import { SearchBar } from "../common/SearchBar";
import { useDialogApiContext } from "../DialogApiContext";
import { ErrorMessage } from "../common/ErrorMessage";
import { SignInToContinue } from "../common/SignInToContinue";

// Retrieve conversations from API in batches
const BATCH_SIZE = 20;

type Props = NativeStackScreenProps<
  MyDialogStackParamList,
  "MyDialogListScreen"
>;

export const MyDialogListScreen: React.FC<Props> = ({ navigation }) => {
  const [conversations, setConversations] = useState<ConversationSearchItem[]>(
    [],
  );
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchResults, setIsSearchResults] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const { dialogClient, latestWebsocketMessage } = useDialogApiContext();

  const { user, fetchUserStatus } = useAuthContext();

  useEffect(() => {
    if (user) {
      fetchDataSync(true);
    }
  }, [user?.userId]);

  useEffect(() => {
    if (latestWebsocketMessage) {
      const { conversationTopicId, conversationId } =
        latestWebsocketMessage.message;
      setMostRecentConversation(conversationTopicId, conversationId).catch(
        console.error,
      );
    }
  }, [latestWebsocketMessage]);

  const fetchData = async (refresh = false, query?: string): Promise<void> => {
    if (isLoading) return;

    if (refresh) {
      setConversations([]);
    }

    setIsLoading(true);

    try {
      const cursor = refresh ? undefined : nextCursor;
      const data = await dialogClient.searchMyConversations(
        query,
        BATCH_SIZE,
        "descending",
        cursor,
      );

      if (refresh) {
        setConversations(data.records);
      } else {
        setConversations([...conversations, ...data.records]);
      }
      setNextCursor(data.nextCursor);

      setErrorMessage(undefined);

      if (query) {
        setIsSearchResults(true);
        if (data.records.length === 0) {
          setErrorMessage(`No results found for "${query}"`);
        }
      } else {
        setIsSearchResults(false);
      }
    } catch (error) {
      setErrorMessage("Could not load my dialogs");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataSync = (refresh = false, query?: string): void => {
    fetchData(refresh, query).catch(console.error);
  };

  const setMostRecentConversation = async (
    conversationTopicId: string,
    conversationId: string,
  ): Promise<void> => {
    const conversationItem = await getConversationItem(
      conversationTopicId,
      conversationId,
    );

    if (conversationItem) {
      setConversations((previousConversations) => {
        const conversationIndex = previousConversations.findIndex(
          (conversation) => conversation.conversationId === conversationId,
        );

        // We check again that the conversation is in the list since the previous check is async and outside of setConversation
        if (conversationIndex !== -1) {
          previousConversations.splice(conversationIndex, 1);
          return [conversationItem, ...previousConversations];
        } else {
          return previousConversations;
        }
      });
    }
  };

  const getConversationItem = async (
    conversationTopicId: string,
    conversationId: string,
  ): Promise<ConversationSearchItem | undefined> => {
    const conversation = conversations.find(
      (conversation) => conversation.conversationId === conversationId,
    );

    if (!conversation) {
      return;
    }

    if (conversation.status === ConversationStatus.OPEN) {
      const {
        metadata: { status },
        users,
      } = await dialogClient.getConversation(
        conversationTopicId,
        conversationId,
        0,
      );

      return {
        ...conversation,
        status,
        users: users.map(({ userId, name, role, pictureUrl }) => ({
          userId,
          name,
          role,
          pictureUrl,
        })),
      };
    }

    return conversation;
  };

  const handleItemPress = (item: ConversationSearchItem): void => {
    const { conversationTopicId, conversationId, title } = item;
    navigation.navigate("MyDialogScreen", {
      conversationTopicId,
      conversationId,
      title,
    });
  };

  const renderDialogListItem = ({
    item,
  }: {
    item: ConversationSearchItem;
  }): React.JSX.Element => {
    if (!user?.userId) {
      // TODO
      return <></>;
    }
    return (
      <MyDialogListItem
        item={item}
        userId={user.userId}
        handleItemPress={handleItemPress}
      />
    );
  };

  const handleEndReached = (): void => {
    // Fetch more data when reaching the end of the list
    if (nextCursor) {
      if (searchQuery === "") {
        fetchDataSync();
      } else {
        fetchDataSync(false, searchQuery);
      }
    }
  };

  const handleSearch = (): void => {
    fetchDataSync(true, searchQuery);
  };

  const handleChangeSearchText = (text: string): void => {
    setSearchQuery(text);
    if (text === "" && isSearchResults) {
      fetchDataSync(true);
    }
  };

  if (fetchUserStatus === "PENDING") {
    return <></>;
  }

  return user && fetchUserStatus === "COMPLETE" ? (
    <View style={{ flex: 1 }}>
      <SearchBar
        query={searchQuery}
        placeholder="Search My Dialogs..."
        handleChangeSearchText={handleChangeSearchText}
        handleSearch={handleSearch}
      />
      {errorMessage && (
        <View style={{ marginTop: 10, maxWidth: 1000, alignSelf: "center" }}>
          <ErrorMessage errorMessage={errorMessage} />
        </View>
      )}
      <FlatList
        data={conversations}
        renderItem={({ item }) => renderDialogListItem({ item })}
        keyExtractor={(item) => item.conversationId}
        onEndReached={handleEndReached}
        onEndReachedThreshold={0.1}
        refreshing={isLoading}
        onRefresh={() => {
          fetchDataSync(true);
        }}
      />
    </View>
  ) : (
    <SignInToContinue action="view your dialogs" />
  );
};
