import { View, Text, Pressable } from "react-native";
import styles from "../styles";
import { type ConversationTitleSearchItem } from "../client/DialogClientModels";
import { ProfileIcon } from "../common/ProfileIcon";

type Props = {
  item: ConversationTitleSearchItem;
  handleItemPress: (title: string) => void;
};

export const OpenDialogListItem: React.FC<Props> = ({
  item,
  handleItemPress,
}) => {
  const { title, conversationCount } = item;

  const prefix =
    conversationCount === 1
      ? `${conversationCount} person wants`
      : `${conversationCount} people want`;

  return (
    <Pressable
      onPress={() => {
        handleItemPress(item.title);
      }}
    >
      <View style={styles.DialogListItem}>
        <ProfileIcon name={conversationCount.toString()} />
        <Text style={styles.topicText}>
          {prefix} a dialog with {title}
        </Text>
      </View>
    </Pressable>
  );
};
