import { type ReactNode, useEffect, useState, useLayoutEffect } from "react";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { type PublicDialogStackParamList } from "./PublicDialogsStack";
import {
  ConversationUserRole,
  ConversationVote,
  type GetConversationResponse,
} from "../client/DialogClientModels";
import {
  Message,
  type IMessage,
  type BubbleProps,
  Bubble,
  type AvatarProps,
  Avatar,
} from "react-native-gifted-chat";
import { makeGiftedChatMessage } from "../common/DialogUtil";
import { PublicDialogTopMenu } from "./menu/PublicDialogTopMenu";
import { useAuthContext } from "../AuthContext";
import { PublicDialogVoteMenu } from "./menu/PublicDialogVoteMenu";
import { ActivityIndicator, FlatList, View } from "react-native";
import { Banner, BannerType } from "../common/Banner";
import { useDialogApiContext } from "../DialogApiContext";
import { ErrorMessage } from "../common/ErrorMessage";
import { HeaderTitle } from "../common/HeaderTitle";

type Props = NativeStackScreenProps<
  PublicDialogStackParamList,
  "PublicDialogScreen"
>;

export const PublicDialogScreen: React.FC<Props> = ({ route, navigation }) => {
  const { dialogClient } = useDialogApiContext();
  const { user, fetchUserStatus } = useAuthContext();
  const userId = user?.userId;

  const { conversationTopicId, conversationId, title } = route.params;

  const [conversation, setConversation] = useState<GetConversationResponse>();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  useLayoutEffect(() => {
    if (conversation) {
      setConversation(undefined);
    }

    if (fetchUserStatus !== "PENDING") {
      fetchConversation().catch(console.error);
    }
  }, [conversationId, fetchUserStatus]);

  useEffect(() => {
    if (conversation) {
      navigation.setOptions({
        headerTitle: () => <HeaderTitle title={conversation.metadata.title} />,
        title: `Dialog with ${conversation.metadata.title}`,
        headerRight: () => (
          <PublicDialogTopMenu
            metadata={conversation?.metadata}
            report={conversation?.report}
            fetchConversation={fetchConversation}
          />
        ),
      });
    } else if (title) {
      navigation.setOptions({
        headerTitle: () => <HeaderTitle title={title} />,
        title: `Dialog with ${title}`,
      });
    }
  }, [conversation?.metadata, conversation?.report]);

  const addVote = (vote: ConversationVote): void => {
    setConversation((previousConversation) => {
      if (!previousConversation || !userId || previousConversation.vote) {
        return previousConversation;
      }

      const userVote = {
        conversationTopicId,
        conversationId,
        userId,
        vote,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      const { metadata: previousMetadata } = previousConversation;
      const { upvotes: previousUpvotes = 0 } = previousMetadata;

      const upvotes =
        vote === ConversationVote.UPVOTE
          ? previousUpvotes + 1
          : previousUpvotes;

      return {
        ...previousConversation,
        metadata: { ...previousMetadata, upvotes },
        vote: userVote,
      };
    });
  };

  const fetchConversation = async (): Promise<void> => {
    try {
      const response = userId
        ? await dialogClient.getConversation(
            conversationTopicId,
            conversationId,
            10000,
            "ascending",
          )
        : await dialogClient.getPublicConversation(
            conversationTopicId,
            conversationId,
          );

      setConversation(response);
      setMessages(
        response.messages.records.map((message) =>
          makeGiftedChatMessage(
            message,
            response.users,
            response.metadata.title,
          ),
        ),
      );
      setErrorMessage(undefined);
    } catch (error) {
      setErrorMessage("Could not load dialog");
    }
  };

  const renderAvatar = (props: AvatarProps<IMessage>): ReactNode => {
    return (
      <Avatar
        {...props}
        containerStyle={{ left: { marginRight: 0 }, right: { marginLeft: 0 } }}
      />
    );
  };

  const renderBubble = (props: BubbleProps<IMessage>): ReactNode => {
    return (
      <Bubble
        {...props}
        renderTime={() => null}
        renderUsernameOnMessage={true}
      />
    );
  };

  const isCreatorMessage = (message: IMessage): boolean => {
    return (
      conversation?.users.find(
        ({ role }) => role === ConversationUserRole.CREATOR,
      )?.userId === message.user._id
    );
  };

  const renderItem = ({
    item,
    index,
  }: {
    item: IMessage;
    index: number;
  }): React.JSX.Element => {
    const previousMessage = messages[index - 1] ?? undefined;
    const nextMessage = messages[index + 1] ?? undefined;
    return (
      <Message
        key={item._id}
        position={isCreatorMessage(item) ? "right" : "left"}
        currentMessage={item}
        previousMessage={previousMessage}
        nextMessage={nextMessage}
        renderBubble={renderBubble}
        renderAvatar={renderAvatar}
      />
    );
  };

  return (
    <View style={{ flex: 1 }}>
      {conversation?.report && (
        <Banner
          message="You have reported this dialog. We will review it to determine the appropriate next steps. No further action is required from you."
          type={BannerType.ALERT}
        />
      )}
      {errorMessage && (
        <View style={{ marginTop: 10, maxWidth: 1000, alignSelf: "center" }}>
          <ErrorMessage errorMessage={errorMessage} />
        </View>
      )}
      {conversation ? (
        <View style={{ flex: 1 }}>
          <FlatList
            data={messages}
            renderItem={renderItem}
            refreshing={false}
          />
          <PublicDialogVoteMenu
            metadata={conversation?.metadata}
            currentVote={conversation?.vote?.vote}
            addVote={addVote}
          />
        </View>
      ) : (
        <ActivityIndicator size="small" style={{ margin: 20 }} />
      )}
    </View>
  );
};
