import { useState, useEffect } from "react";
import { FlatList, View } from "react-native";
import { PublicDialogListItem } from "./PublicDialogListItem";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { type PublicDialogStackParamList } from "./PublicDialogsStack";
import { type ConversationSearchItem } from "../client/DialogClientModels";
import { SearchBar } from "../common/SearchBar";
import { useDialogApiContext } from "../DialogApiContext";
import { ErrorMessage } from "../common/ErrorMessage";

const RECENT_BATCH_SIZE = 20;

type Props = NativeStackScreenProps<
  PublicDialogStackParamList,
  "PublicDialogListScreen"
>;

export const PublicDialogListScreen: React.FC<Props> = ({ navigation }) => {
  const [conversations, setConversations] = useState<ConversationSearchItem[]>(
    [],
  );
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchResults, setIsSearchResults] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const { dialogClient } = useDialogApiContext();

  useEffect(() => {
    // Fetch initial chat data
    if (!conversations.length) {
      fetchData().then(console.log).catch(console.error);
    }
  }, []);

  const fetchData = async (refresh = false, query?: string): Promise<void> => {
    if (isLoading) return;

    if (refresh) {
      setConversations([]);
    }

    setIsLoading(true);

    try {
      const cursor = refresh ? undefined : nextCursor;

      const [topConversations, newConversations] = await Promise.all([
        dialogClient.searchPublicConversations(
          query,
          query ? undefined : "RECENT",
          RECENT_BATCH_SIZE,
          "descending",
          cursor,
        ),
        // We mix in two new conversations to the default list to get some exposure
        // TODO: consider retrieving more new conversations and randomly drawing two
        // Also consider moving this logic to the backend, so it only requires one API call
        cursor ?? query
          ? { records: [] }
          : await dialogClient.searchPublicConversations(
              undefined,
              "NEW",
              2,
              "descending",
            ),
      ]);

      const allConversations = [
        ...topConversations.records,
        ...newConversations.records.filter(
          ({ conversationId }) =>
            !topConversations.records.some(
              (topConversation) =>
                topConversation.conversationId === conversationId,
            ),
        ),
      ];

      // Randomly sort each batch of conversations, so different ones get top billing
      const shuffledConversations = allConversations.toSorted(
        () => 0.5 - Math.random(),
      );

      // Update the request data and nextCursor
      if (refresh) {
        setConversations(shuffledConversations);
      } else {
        setConversations((previousConversations) => [
          ...previousConversations,
          ...shuffledConversations,
        ]);
      }
      setNextCursor(topConversations.nextCursor);

      setErrorMessage(undefined);

      if (query) {
        setIsSearchResults(true);
        if (shuffledConversations.length === 0) {
          setErrorMessage(`No results found for "${query}"`);
        }
      } else {
        setIsSearchResults(false);
      }
    } catch (error) {
      setErrorMessage("Could not load public dialogs");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataSync = (refresh = false, query?: string): void => {
    fetchData(refresh, query).catch(console.error);
  };

  const handleItemPress = (item: ConversationSearchItem): void => {
    const { conversationTopicId, conversationId, title } = item;
    navigation.navigate("PublicDialogScreen", {
      conversationTopicId,
      conversationId,
      title,
    });
  };

  const renderPublicDialogItem = ({
    item,
  }: {
    item: ConversationSearchItem;
  }): React.JSX.Element => {
    return (
      <PublicDialogListItem
        item={item}
        handleItemPress={handleItemPress}
      ></PublicDialogListItem>
    );
  };

  const handleEndReached = (): void => {
    // Fetch more data when reaching the end of the list
    if (nextCursor) {
      if (searchQuery === "") {
        fetchDataSync();
      } else {
        fetchDataSync(false, searchQuery);
      }
    }
  };

  const handleSearch = (): void => {
    fetchDataSync(true, searchQuery);
  };

  const handleChangeSearchText = (text: string): void => {
    setSearchQuery(text);
    if (text === "" && isSearchResults) {
      fetchDataSync(true);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <SearchBar
        query={searchQuery}
        placeholder="Search Public Dialogs..."
        handleChangeSearchText={handleChangeSearchText}
        handleSearch={handleSearch}
      />
      {errorMessage && (
        <View style={{ marginTop: 10, maxWidth: 1000, alignSelf: "center" }}>
          <ErrorMessage errorMessage={errorMessage} />
        </View>
      )}
      <FlatList
        data={conversations}
        renderItem={renderPublicDialogItem}
        // TODO fix keyExtractors
        keyExtractor={(item) => item.conversationId}
        onEndReached={handleEndReached}
        onEndReachedThreshold={0.1}
        refreshing={isLoading}
        onRefresh={() => {
          fetchDataSync(true);
        }}
      />
    </View>
  );
};
