import { Text, View } from "react-native";
import styles from "../styles";

type Props = {
  errorMessage?: string;
};

export const ErrorMessage: React.FC<Props> = ({ errorMessage }) => {
  return (
    <View
      style={{
        ...styles.errorContainer,
        backgroundColor: errorMessage && "pink",
      }}
    >
      <Text style={styles.errorText}>{errorMessage}</Text>
    </View>
  );
};
