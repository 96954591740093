import { Pressable, View, Text } from "react-native";
import { Feather } from "@expo/vector-icons";
import {
  type ConversationMetadataResponse,
  ConversationVote,
} from "../../client/DialogClientModels";
import { useAuthContext } from "../../AuthContext";
import { useDialogApiContext } from "../../DialogApiContext";

type MenuProps = {
  metadata?: ConversationMetadataResponse;
  currentVote?: ConversationVote;
  addVote: (vote: ConversationVote) => void;
};

type VoteProps = {
  metadata: ConversationMetadataResponse;
  vote: ConversationVote;
  currentVote?: ConversationVote;
  addVote: (vote: ConversationVote) => void;
};

const VoteMenu: React.FC<VoteProps> = ({
  metadata,
  vote,
  currentVote,
  addVote,
}) => {
  const { dialogClient } = useDialogApiContext();
  const { user } = useAuthContext();

  const getColor = (): string => {
    if (!currentVote) {
      return "black";
    }

    if (currentVote !== vote) {
      return "gray";
    }

    return vote === ConversationVote.UPVOTE ? "green" : "red";
  };

  const onPress = async (): Promise<void> => {
    // TODO: move to addVote
    await dialogClient.voteOnConversation(
      metadata.conversationTopicId,
      metadata.conversationId,
      vote,
    );
    addVote(vote);
  };

  const onPressSync = (): void => {
    onPress().catch(console.error);
  };

  return (
    <Pressable
      disabled={user !== undefined && currentVote !== undefined}
      onPress={onPressSync}
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginHorizontal: 12,
        padding: 5,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: getColor(),
      }}
    >
      {vote === ConversationVote.UPVOTE && (
        <Text style={{ marginRight: 10, color: getColor() }}>
          {metadata.upvotes ?? 0}
        </Text>
      )}
      <Feather
        name={vote === ConversationVote.UPVOTE ? "thumbs-up" : "thumbs-down"}
        size={25}
        color={getColor()}
      />
    </Pressable>
  );
};

export const PublicDialogVoteMenu: React.FC<MenuProps> = ({
  metadata,
  currentVote,
  addVote,
}) => {
  if (!metadata) {
    return <></>;
  }

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
      }}
    >
      <VoteMenu
        metadata={metadata}
        vote={ConversationVote.UPVOTE}
        currentVote={currentVote}
        addVote={addVote}
      />
      <VoteMenu
        metadata={metadata}
        vote={ConversationVote.DOWNVOTE}
        currentVote={currentVote}
        addVote={addVote}
      />
    </View>
  );
};
