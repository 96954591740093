import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { MenuScreen } from "./MenuScreen";
import { AboutDialogScreen } from "./AboutDialogScreen";
import { FaqScreen } from "./FaqScreen";
import { PrivacyPolicyScreen } from "./PrivacyPolicyScreen";
import { RulesAndPoliciesScreen } from "./RulesAndPoliciesScreen";
import { TermsOfServiceScreen } from "./TermsOfServiceScreen";
import { ProfileScreen } from "./profile/ProfileScreen";
import { UserSettingsScreen } from "./UserSettingsScreen";
import { NotFoundScreen } from "./NotFoundScreen";

export type MenuStackParamList = {
  MenuScreen: undefined;
  ProfileScreen: undefined;
  AboutDialogScreen: undefined;
  FaqScreen: undefined;
  PrivacyPolicyScreen: undefined;
  RulesAndPoliciesScreen: undefined;
  TermsOfServiceScreen: undefined;
  UserSettingsScreen: undefined;
  NotFoundScreen: undefined;
};

const Stack = createNativeStackNavigator<MenuStackParamList>();

export const MenuStack: React.FC = () => {
  return (
    <Stack.Navigator initialRouteName="MenuScreen">
      <Stack.Screen
        name="MenuScreen"
        component={MenuScreen}
        options={{
          title: "Menu",
          headerShown: false,
          headerBackVisible: false,
        }}
      />
      <Stack.Screen
        name="AboutDialogScreen"
        component={AboutDialogScreen}
        options={{
          title: "About Dialog",
          headerShown: true,
          headerBackVisible: true,
        }}
      />
      <Stack.Screen
        name="FaqScreen"
        component={FaqScreen}
        options={{
          title: "Dialog FAQ",
          headerShown: true,
          headerBackVisible: true,
        }}
      />
      <Stack.Screen
        name="PrivacyPolicyScreen"
        component={PrivacyPolicyScreen}
        options={{
          title: "Dialog Privacy Policy",
          headerShown: true,
          headerBackVisible: true,
        }}
      />
      <Stack.Screen
        name="RulesAndPoliciesScreen"
        component={RulesAndPoliciesScreen}
        options={{
          title: "Dialog Rules and Policies",
          headerShown: true,
          headerBackVisible: true,
        }}
      />
      <Stack.Screen
        name="TermsOfServiceScreen"
        component={TermsOfServiceScreen}
        options={{
          title: "Dialog Terms of Service",
          headerShown: true,
          headerBackVisible: true,
        }}
      />
      <Stack.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={{
          title: "Profile",
          headerShown: true,
          headerBackVisible: true,
        }}
      />
      <Stack.Screen
        name="UserSettingsScreen"
        component={UserSettingsScreen}
        options={{
          title: "Settings",
          headerShown: true,
          headerBackVisible: true,
        }}
      />
      <Stack.Screen
        name="NotFoundScreen"
        component={NotFoundScreen}
        options={{
          title: "Page Not Found",
          headerShown: true,
          headerBackVisible: true,
        }}
      />
    </Stack.Navigator>
  );
};
