import { Image, View } from "react-native";
import styles from "./styles";

export const AppHeader: React.FC = () => {
  return (
    <View style={styles.appNameContainer}>
      <Image
        source={require("../assets/logo-dark-blue.png")}
        style={{
          width: 163,
          height: 75,
          aspectRatio: 1,
        }}
      ></Image>
    </View>
  );
};
