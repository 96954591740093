import {
  type NativeStackScreenProps,
  createNativeStackNavigator,
} from "@react-navigation/native-stack";
import { PublicDialogListScreen } from "./PublicDialogListScreen";
import { PublicDialogScreen } from "./PublicDialogScreen";
import { useEffect } from "react";
import { useAuthContext } from "../AuthContext";

export type PublicDialogStackParamList = {
  PublicDialogListScreen: undefined;
  PublicDialogScreen: {
    conversationTopicId: string;
    conversationId: string;
    title?: string;
  };
};

const Stack = createNativeStackNavigator<PublicDialogStackParamList>();

export const PublicDialogsStack: React.FC = () => {
  const { setRequiresLogin } = useAuthContext();

  useEffect(() => {
    setRequiresLogin(false);
  }, []);

  return (
    <Stack.Navigator initialRouteName="PublicDialogListScreen">
      <Stack.Screen
        name="PublicDialogListScreen"
        component={PublicDialogListScreen}
        options={{
          title: "Public Dialogs",
          headerShown: true,
          headerBackVisible: false,
        }}
      />
      <Stack.Screen
        name="PublicDialogScreen"
        component={PublicDialogScreen}
        options={({
          route,
        }: NativeStackScreenProps<
          PublicDialogStackParamList,
          "PublicDialogScreen"
        >) => ({
          headerTitle: route.params.title,
          title: route.params.title ? `Dialog with ${route.params.title}` : "",
          headerShown: true,
          headerBackVisible: true,
        })}
      />
    </Stack.Navigator>
  );
};
