import { useState } from "react";
import { Text, View, Pressable } from "react-native";
import styles from "../styles";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { type CreateAccountParamList } from "./CreateAccountStack";
import { useAuthenticator } from "@aws-amplify/ui-react-native";
import { AppHeader } from "../AppHeader";
import { ErrorMessage } from "../common/ErrorMessage";
import { ContinueWithGoogleButton } from "../common/ContinueWithGoogleButton";
import { A } from "@expo/html-elements";

const DIALOG_BASE_URL = process.env.DIALOG_BASE_URL ?? "";

type Props = NativeStackScreenProps<
  CreateAccountParamList,
  "CreateAccountScreen"
>;

export const CreateAccountScreen: React.FC<Props> = ({ navigation }) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const { toSignIn } = useAuthenticator();

  return (
    <View style={styles.authFlowContainer}>
      <View style={{ borderBottomColor: "black", borderBottomWidth: 1 }}>
        <AppHeader />
      </View>
      <Text style={styles.authFlowHeader}>Create Account</Text>
      <Text
        style={{
          fontSize: 16,
          marginHorizontal: 10,
          marginTop: 20,
          marginBottom: 10,
        }}
      >
        {"By continuing, you agree to the Dialog "}
        <A href={`${DIALOG_BASE_URL}/privacy-policy`} style={styles.linkText}>
          Privacy Policy
        </A>
        {", "}
        <A href={`${DIALOG_BASE_URL}/terms-of-service`} style={styles.linkText}>
          Terms of Service
        </A>
        {", and "}
        <A
          href={`${DIALOG_BASE_URL}/rules-and-policies`}
          style={styles.linkText}
        >
          Rules & Policies
        </A>
      </Text>
      <Text style={{ fontSize: 16, marginHorizontal: 10, marginBottom: 10 }}>
        {"This site is protected by reCAPTCHA and the Google "}
        <A href="https://policies.google.com/privacy" style={styles.linkText}>
          Privacy Policy
        </A>
        {" and "}
        <A href="https://policies.google.com/terms" style={styles.linkText}>
          Terms of Service
        </A>
        {" apply."}
      </Text>
      <ContinueWithGoogleButton
        onError={() => {
          setErrorMessage("An error occurred");
        }}
      />
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: 320,
          marginVertical: 20,
        }}
      >
        <View style={{ flex: 1, height: 1, backgroundColor: "black" }} />
        <Text style={{ marginHorizontal: 5, fontSize: 16 }}>or</Text>
        <View style={{ flex: 1, height: 1, backgroundColor: "black" }} />
      </View>
      <Pressable
        onPress={() => {
          navigation.navigate("CreateAccountWithEmailScreen");
        }}
        style={{
          width: 320,
          height: 68,
          borderRadius: 34,
          borderWidth: 1,
          borderColor: "black",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "blue",
        }}
      >
        <Text style={{ fontSize: 22, fontWeight: "500", color: "white" }}>
          Create Account with Email
        </Text>
      </Pressable>
      <View style={{ marginTop: 10 }}>
        <ErrorMessage errorMessage={errorMessage} />
      </View>
      <Pressable onPress={toSignIn} style={{ marginTop: 10 }}>
        <Text style={styles.linkText}>Sign In</Text>
      </Pressable>
    </View>
  );
};
