import { Image, View } from "react-native";
import { useAuthContext } from "../AuthContext";
import { Feather } from "@expo/vector-icons";
import { useEffect } from "react";
import { AsyncButton } from "../common/Button";
import { MenuLink } from "./MenuLink";
import { getThumbnailUrl } from "../common/DialogUtil";

export const MenuScreen: React.FC = () => {
  const { user, requiresLogin, setRequiresLogin, signOut } = useAuthContext();

  useEffect(() => {
    if (requiresLogin) {
      setRequiresLogin(false);
    }
  }, []);

  return (
    <View style={{ flex: 1, margin: 20 }}>
      {user ? (
        <View>
          <MenuLink
            icon={
              user.pictureUrl ? (
                <Image
                  source={{ uri: getThumbnailUrl(user.pictureUrl) }}
                  style={{ width: 30, height: 30, borderRadius: 15 }}
                ></Image>
              ) : (
                <Feather name="user" size={30} color="blue" />
              )
            }
            linkText="Profile"
            linkTo="/profile"
          />
          <MenuLink
            icon={<Feather name="settings" size={30} color="blue" />}
            linkText="Settings"
            linkTo="/settings"
          />
        </View>
      ) : (
        <MenuLink
          icon={<Feather name="user" size={30} color="blue" />}
          linkText="Sign In / Sign Up"
          onPress={() => {
            setRequiresLogin(true);
          }}
        />
      )}
      <MenuLink
        icon={<Feather name="info" size={30} color="blue" />}
        linkText="About Dialog"
        linkTo="/about"
      />
      <MenuLink
        icon={<Feather name="help-circle" size={30} color="blue" />}
        linkText="FAQ"
        linkTo="/faq"
      />
      <MenuLink
        icon={<Feather name="shield" size={30} color="blue" />}
        linkText="Privacy Policy"
        linkTo="/privacy-policy"
      />
      <MenuLink
        icon={<Feather name="clipboard" size={30} color="blue" />}
        linkText="Rules and Policies"
        linkTo="/rules-and-policies"
      />
      <MenuLink
        icon={<Feather name="check-square" size={30} color="blue" />}
        linkText="Terms of Service"
        linkTo="/terms-of-service"
      />
      {user && (
        <View style={{ marginTop: 80, alignSelf: "center" }}>
          <AsyncButton onPress={signOut} isAlert buttonText="Sign Out" />
        </View>
      )}
    </View>
  );
};
