import { Text, View } from "react-native";
import { SlideUpMenuButton } from "../../common/SlideUpMenuButton";
import { Feather } from "@expo/vector-icons";
import styles from "../../styles";
import { type ConversationMetadataResponse } from "../../client/DialogClientModels";
import { useState } from "react";
import { AsyncButton } from "../../common/Button";
import { useDialogApiContext } from "../../DialogApiContext";

export const MyDialogDeleteMenu: React.FC<{
  metadata: ConversationMetadataResponse;
  setIsDeleted: () => void;
}> = ({ metadata, setIsDeleted }) => {
  const { dialogClient } = useDialogApiContext();

  const [isOpen, setIsOpen] = useState(false);

  const DeleteButton: React.FC = () => {
    return (
      <View style={styles.topMenuButton}>
        <Feather name="trash" size={20} style={styles.topMenuAlertIcon} />
        <Text style={styles.topMenuAlertText}>Delete</Text>
      </View>
    );
  };

  const SlideUp: React.FC = () => {
    const onPress = async (): Promise<void> => {
      await dialogClient.deleteConversation(
        metadata.conversationTopicId,
        metadata.conversationId,
      );
      setIsDeleted();
      setIsOpen(false);
    };

    const messageText =
      "Permanently delete this dialog ?\n\n(This action cannot be undone)";

    return (
      <View style={styles.slideUpContentContainer}>
        <Text style={styles.slideUpText}>{messageText}</Text>
        <AsyncButton onPress={onPress} isAlert buttonText="Delete" />
      </View>
    );
  };

  return (
    <SlideUpMenuButton
      triggerComponent={<DeleteButton />}
      contentComponent={<SlideUp />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
