import { useEffect, useState } from "react";
import { Text, View, Switch, ActivityIndicator, Platform } from "react-native";
import { EmailNotificationTopic } from "../client/DialogClientModels";
import { useDialogApiContext } from "../DialogApiContext";
import { useAuthContext } from "../AuthContext";
import { ErrorMessage } from "../common/ErrorMessage";

export const UserSettingsScreen: React.FC = () => {
  const { dialogClient } = useDialogApiContext();
  const { requiresLogin, setRequiresLogin } = useAuthContext();

  const [emailNotificationSettings, setEmailNotificationSettings] =
    useState<{ [key in EmailNotificationTopic]: boolean }>();
  const [toggleEnabled, setToggleEnabled] = useState<{
    [key in EmailNotificationTopic]: boolean;
  }>({
    DialogJoined: false,
    NewMessage: false,
    PublishRequest: false,
    PublishSuggestion: false,
  });
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!requiresLogin) {
      setRequiresLogin(true);
    }
  }, []);

  useEffect(() => {
    if (!emailNotificationSettings) {
      fetchEmailNotificationSettings().catch(console.error);
    }
  }, [emailNotificationSettings]);

  const fetchEmailNotificationSettings = async (): Promise<void> => {
    try {
      const settings = await dialogClient.getEmailNotificationSettings();
      setErrorMessage(undefined);
      setEmailNotificationSettings(settings);
      setToggleEnabled({
        DialogJoined: true,
        NewMessage: true,
        PublishRequest: true,
        PublishSuggestion: true,
      });
    } catch (error) {
      setErrorMessage("Coule not load email notification settings");
    }
  };

  const toggleEmailNotificationSettings = async (
    topic: EmailNotificationTopic,
    isEnabled: boolean,
  ): Promise<void> => {
    try {
      if (!emailNotificationSettings) {
        return;
      }
      setToggleEnabled((previousToggleEnabled) => ({
        ...previousToggleEnabled,
        [topic]: false,
      }));
      await dialogClient.updateEmailNotificationSetting(topic, isEnabled);
      setErrorMessage(undefined);
      setEmailNotificationSettings((previousEmailNotificationSettings) => {
        if (!previousEmailNotificationSettings) {
          return;
        }
        return { ...previousEmailNotificationSettings, [topic]: isEnabled };
      });
    } catch (error) {
      setErrorMessage("Could not update email notification settings");
    } finally {
      setToggleEnabled((previousToggleEnabled) => ({
        ...previousToggleEnabled,
        [topic]: true,
      }));
    }
  };

  const toggleEmailNotificationSettingsSync = (
    topic: EmailNotificationTopic,
    isEnabled: boolean,
  ): void => {
    toggleEmailNotificationSettings(topic, isEnabled).catch(console.error);
  };

  const EmailNotificationSetting: React.FC<{
    label: string;
    topic: EmailNotificationTopic;
  }> = ({ label, topic }) => {
    if (!emailNotificationSettings) {
      return;
    }
    return (
      <View
        style={{
          flexDirection: "row",
          margin: 10,
          maxWidth: 400,
          justifyContent: "space-between",
        }}
      >
        <Text style={{ fontSize: 18, marginRight: 10 }}>{label}</Text>
        <Switch
          value={emailNotificationSettings[topic]}
          onValueChange={async (isEnabled) => {
            toggleEmailNotificationSettingsSync(topic, isEnabled);
          }}
          disabled={!toggleEnabled[topic]}
          thumbColor={emailNotificationSettings[topic] ? "blue" : "gray"}
          trackColor={{ false: "lightgray", true: "lightblue" }}
          activeThumbColor={"blue"}
          {...Platform.select({
            web: {
              activeThumbColor: "blue",
            },
          })}
        />
      </View>
    );
  };

  return (
    <View style={{ flex: 1, flexDirection: "column" }}>
      {errorMessage && (
        <View style={{ marginTop: 10, maxWidth: 1000, alignSelf: "center" }}>
          <ErrorMessage errorMessage={errorMessage} />
        </View>
      )}
      {emailNotificationSettings && (
        <View style={{ flexDirection: "column" }}>
          <Text style={{ fontSize: 20, margin: 10, fontWeight: "500" }}>
            Select which email notifications you would like to receive
          </Text>
          <EmailNotificationSetting
            label="Someone joined your dialog"
            topic={EmailNotificationTopic.DialogJoined}
          />
          <EmailNotificationSetting
            label="New message in your dialog"
            topic={EmailNotificationTopic.NewMessage}
          />
          <EmailNotificationSetting
            label="Request to publish your dialog"
            topic={EmailNotificationTopic.PublishRequest}
          />
          <EmailNotificationSetting
            label="Suggestion to publish your dialog"
            topic={EmailNotificationTopic.PublishSuggestion}
          />
        </View>
      )}
      {!emailNotificationSettings && !errorMessage && (
        <ActivityIndicator size="small" style={{ margin: 20 }} />
      )}
    </View>
  );
};
