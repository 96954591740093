type WithTimestamps<T> = T & {
  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export interface Batch<T> {
  readonly nextCursor?: string;
  readonly records: T[];
}

export enum ConversationType {
  ONE_ON_ONE = "ONE_ON_ONE",
  BOT = "BOT",
}

export enum ConversationStatus {
  DELETED = "DELETED",
  OPEN = "OPEN",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
  REMOVED = "REMOVED",
}

export enum ConversationUserRole {
  CREATOR = "CREATOR",
  RESPONDER = "RESPONDER",
}

export enum ConversationReportContext {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  TOPIC_TITLE = "TOPIC_TITLE",
}

export enum ConversationVote {
  UPVOTE = "UPVOTE",
  DOWNVOTE = "DOWNVOTE",
}

export interface CreateTopicResponse {
  readonly topic: ConversationTopicResponse;
  readonly metadata: ConversationMetadataResponse;
  readonly users: Array<Omit<ConversationUserResponse, "name" | "pictureUrl">>;
}

export interface GetConversationResponse {
  readonly metadata: ConversationMetadataResponse;
  readonly users: ConversationUserResponse[];
  readonly messages: Batch<ConversationMessageResponse>;
  readonly report?: ConversationUserReportResponse;
  readonly vote?: ConversationUserVoteResponse;
}

export type SearchConversationsResponse = Batch<ConversationSearchItem>;

export type SearchOpenConversationTitlesResponse =
  Batch<ConversationTitleSearchItem>;

export type GetMessagesResponse = Batch<ConversationMessageResponse>;

export type ConversationMessageResponse = WithTimestamps<{
  readonly conversationTopicId: string;
  readonly conversationId: string;
  readonly userId: string;
  readonly messageId: string;
  readonly content: string;
}>;

export type ConversationUserReportResponse = WithTimestamps<{
  readonly conversationTopicId: string;
  readonly conversationId: string;
  readonly userId: string;
  readonly reportContext: ConversationReportContext;
}>;

export type ConversationUserVoteResponse = WithTimestamps<{
  readonly conversationTopicId: string;
  readonly conversationId: string;
  readonly userId: string;
  readonly vote: ConversationVote;
}>;

export type ConversationTopicResponse = WithTimestamps<{
  readonly conversationTopicId: string;
  readonly title: string;
  readonly createdByUserId: string;
}>;

export type ConversationMetadataResponse = WithTimestamps<{
  readonly conversationTopicId: string;
  readonly conversationId: string;
  readonly title: string;
  readonly type: ConversationType;
  readonly status: ConversationStatus;
  readonly upvotes?: number;
}>;

export type ConversationUserResponse = WithTimestamps<{
  readonly conversationTopicId: string;
  readonly conversationId: string;
  readonly userId: string;
  readonly name: string;
  readonly pictureUrl?: string;
  readonly role: ConversationUserRole;
  readonly requestedPublicConversation: boolean;
  readonly lastMessageTime?: Date;
}>;

export interface ConversationSearchItem {
  readonly conversationId: string;
  readonly conversationTopicId: string;
  readonly createdAt: Date;
  readonly title: string;
  readonly type: ConversationType;
  readonly status: ConversationStatus;
  readonly users: ConversationSearchItemUser[];
}

export interface ConversationSearchItemUser {
  readonly userId: string;
  readonly name: string;
  readonly role: ConversationUserRole;
  readonly pictureUrl?: string;
}

export interface ConversationTitleSearchItem {
  readonly title: string;
  readonly conversationCount: number;
}

export enum EmailNotificationTopic {
  DialogJoined = "DialogJoined",
  NewMessage = "NewMessage",
  PublishRequest = "PublishRequest",
  PublishSuggestion = "PublishSuggestion",
}

export enum WebsocketMessageType {
  NEW_MESSAGE = "NEW_MESSAGE",
  DIALOG_JOINED = "DIALOG_JOINED",
  PUBLISH_REQUEST = "PUBLISH_REQUEST",
}

export interface NewMessageWebsocketMessage {
  type: WebsocketMessageType.NEW_MESSAGE;
  message: ConversationMessageResponse;
}

export interface DialogJoinedWebsocketMessage {
  type: WebsocketMessageType.DIALOG_JOINED;
  message: ConversationMetadataResponse;
}

export interface PublishRequestWebsocketMessage {
  type: WebsocketMessageType.PUBLISH_REQUEST;
  message: ConversationMetadataResponse;
}

export type WebsocketMessage =
  | NewMessageWebsocketMessage
  | DialogJoinedWebsocketMessage
  | PublishRequestWebsocketMessage;
