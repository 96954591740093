import { useEffect, useState } from "react";
import { useAuthContext } from "../../AuthContext";
import { Text, View } from "react-native";

export const ProfileContactInformation: React.FC = () => {
  const { user } = useAuthContext();

  const [contacts, setContacts] = useState<{
    email?: string;
    phoneNumber?: string;
  }>({ email: undefined, phoneNumber: undefined });

  useEffect(() => {
    if (user) {
      const { email, phoneNumber } = user;
      setContacts({ email, phoneNumber });
    }
  }, [user]);

  return (
    <View style={{ flexDirection: "column", alignItems: "center" }}>
      {contacts.email && (
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <Text style={{ fontSize: 25 }}>{contacts.email}</Text>
          <Text style={{ fontSize: 16, color: "gray" }}>Email</Text>
        </View>
      )}
      {contacts.phoneNumber && (
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <Text style={{ fontSize: 25 }}>{contacts.phoneNumber}</Text>
          <Text style={{ fontSize: 16, color: "gray" }}>Phone Number</Text>
        </View>
      )}
    </View>
  );
};
