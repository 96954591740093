import {
  type LinkingOptions,
  NavigationContainer,
} from "@react-navigation/native";
import * as Linking from "expo-linking";
import {
  BottomTabNavigator,
  type BottomTabParamList,
} from "./BottomTabNavigator";

const prefix = Linking.createURL("/");

const config = {
  screens: {
    "Open Dialogs": {
      screens: {
        OpenDialogListScreen: "open-dialogs",
        OpenDialogScreen: "open-dialogs/respond",
      },
    },
    "My Dialogs": {
      screens: {
        MyDialogListScreen: "my-dialogs",
        MyDialogScreen:
          "my-dialogs/topic/:conversationTopicId/conversation/:conversationId",
      },
    },
    "Public Dialogs": {
      screens: {
        PublicDialogListScreen: "public-dialogs",
        PublicDialogScreen:
          "public-dialogs/topic/:conversationTopicId/conversation/:conversationId",
      },
    },
    "Start Dialog": "start-dialog",
    More: {
      screens: {
        MenuScreen: "menu",
        AboutDialogScreen: "about",
        FaqScreen: "faq",
        PrivacyPolicyScreen: "privacy-policy",
        RulesAndPoliciesScreen: "rules-and-policies",
        TermsOfServiceScreen: "terms-of-service",
        ProfileScreen: "profile",
        UserSettingsScreen: "settings",
        NotFoundScreen: "*",
      },
    },
  },
};

const linking: LinkingOptions<BottomTabParamList> = {
  prefixes: [prefix],
  config,
};

export const MainNavigation: React.FC = () => {
  return (
    <NavigationContainer linking={linking}>
      <BottomTabNavigator />
    </NavigationContainer>
  );
};
